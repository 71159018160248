import React, { Component } from "react";

import { SimpleCard } from "src/@bootstrap/template/@gull";
import {
  Tab,
  Tabs
} from "react-bootstrap";

export class NotificationsCard extends Component {
 
  handlePageClick = (data) => {
    let currentPage = data.disabled;
    this.setState({ currentPage });
  };

  render() {

    return (
      <SimpleCard title="Notifiche recenti" className="h-100">
                  <Tabs
                    className="justify-content-end"
                    defaultActiveKey="Latest"
                    id="notification"
                  >
                    <Tab eventKey="Latest" title="Ultime">
                      <div className="ul-widget-s7n">
                        <div className="ul-widget-s7__items mb-4">
                          <span className="ul-widget-s7__item-time ul-middle">
                            10:00
                          </span>
                          <div className="ul-widget-s7__item-circle">
                            <p className="ul-vertical-line bg-primary "></p>
                          </div>
                          <div >
                            Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                            <br />
                            incididunt ut labore et dolore magna
                          </div>
                        </div>

                        <div className="ul-widget-s7__items mb-4">
                          <span className="ul-widget-s7__item-time ul-middle">
                            08:00
                          </span>
                          <div className="ul-widget-s7__item-circle">
                            <p className="ul-vertical-line bg-success "></p>
                          </div>
                          <div>
                            Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                            <br />
                            incididunt ut labore et dolore magna
                          </div>
                        </div>

                        <div className="ul-widget-s7__items mb-4">
                          <span className="ul-widget-s7__item-time ul-middle">
                            13:00
                          </span>
                          <div className="ul-widget-s7__item-circle">
                            <p className="ul-vertical-line bg-danger "></p>
                          </div>
                          <div>
                            Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                            <br />
                            incididunt ut labore et dolore magna
                          </div>
                        </div>

                        <div className="ul-widget-s7__items">
                          <span className="ul-widget-s7__item-time ul-middle">
                            05:30
                          </span>
                          <div className="ul-widget-s7__item-circle">
                            <p className="ul-vertical-line bg-warning "></p>
                          </div>
                          <div>
                            Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                            <br />
                            incididunt ut labore et dolore magna
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab className="ul-widget__body" eventKey="Month" title="Questo mese">
                      <div className="ul-widget-s7n">
                        <div className="ul-widget-s7__items mb-4">
                          <span className="ul-widget-s7__item-time ul-middle">
                            12:00
                          </span>
                          <div className="ul-widget-s7__item-circle">
                            <p className="ul-vertical-line bg-danger "></p>
                          </div>
                          <div>
                            Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                            <br />
                            incididunt ut labore et dolore magna
                          </div>
                        </div>

                        <div className="ul-widget-s7__items mb-4">
                          <span className="ul-widget-s7__item-time ul-middle">
                            08:00
                          </span>
                          <div className="ul-widget-s7__item-circle">
                            <p className="ul-vertical-line bg-info "></p>
                          </div>
                          <div>
                            Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                            <br />
                            incididunt ut labore et dolore magna
                          </div>
                        </div>

                        <div className="ul-widget-s7__items mb-4">
                          <span className="ul-widget-s7__item-time ul-middle">
                            04:30
                          </span>
                          <div className="ul-widget-s7__item-circle">
                            <p className="ul-vertical-line bg-warning "></p>
                          </div>
                          <div>
                            Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                            <br />
                            incididunt ut labore et dolore magna
                          </div>
                        </div>

                        <div className="ul-widget-s7__items">
                          <span className="ul-widget-s7__item-time ul-middle">
                            05:30
                          </span>
                          <div className="ul-widget-s7__item-circle">
                            <p className="ul-vertical-line bg-dark "></p>
                          </div>
                          <div>
                            Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                            <br />
                            incididunt ut labore et dolore magna
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
            </SimpleCard>
    );
  }
}

export default NotificationsCard;
