import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/@bootstrap/services/store';
import { Errors } from "src/@bootstrap/types";

interface State {
    message?: string;
    errors?: Errors;
};

const initialState: State = {
    
};

export const reducer = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<string | undefined>) => {
            state.message = action.payload;
        },
        setErrors: (state, action: PayloadAction<Errors | undefined>) => {
            state.errors = action.payload;
        },
    },
});

export const { setMessage, setErrors } = reducer.actions;

export const selectMessage = (state: RootState) => state.app.message;
export const selectErrors = (state: RootState) => state.app.errors;

export default reducer.reducer
