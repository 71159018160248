import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/@bootstrap/services/store';

interface ListOpener {
    name: string;
    query_path: string;
    query_name: string;
}

export interface List {
    nome: string;
    isModificabile: boolean;
    campi?: { nome: string; lunghezza: number; }[];
    graphInfo?: {
        elencoGraphFieldName: string;
        categoriaElencoTypeName: string;
        categoriaElencoGraphFieldName: string;
        queryGraphFieldName: string;
        queryTypeName: string;
    };
}

interface CustomState {
    currentList?: ListOpener;
    lists?: List[];
}

const initialState: CustomState = {
};

export const customReducer = createSlice({
    name: 'custom',
    initialState,
    reducers: {
        manageList: (state, action: PayloadAction<ListOpener | undefined>) => {
            state.currentList = action.payload;
        },
        setLists: (state, action: PayloadAction<List[] | undefined>) => {
            state.lists = action.payload;
        },
    },
});

export const { manageList, setLists } = customReducer.actions;

type State = { custom: CustomState };
export const selectCurrentList = (state: State) => state.custom.currentList;
export const selectLists = (state: State) => state.custom.lists;

export default customReducer.reducer