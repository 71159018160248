import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const scaffaliRoutes = [
  {
    path: "scaffali/List",
    element: <Scaffali />,
    // auth: authRoles.admin
  },
];

function Scaffali() {
  return <Crud module="scaffali" entity="scaffali" />;
}

export default scaffaliRoutes;
