import React from "react";
import ReactEcharts from "echarts-for-react";

interface Props {
  height: number;
  color: string[];
  data?: {
    data: string;
    anno: number;
    mese: number;
    utenti: number;
    // erogati: number;
    // pianificati: number;
  }[];
}

const ProjectsChart = (props: Props) => {
  const { height, color = [], data = [] } = props;
  const option = {
    title: {
      text: "Ultimi utenti registrati",
    },
    legend: {
      borderRadius: 0,
      orient: "horizontal",
      x: "right",
      data: ["Utenti"],
    },
    grid: {
      left: "8px",
      right: "8px",
      bottom: "0",
      containLabel: true,
    },
    tooltip: {
      show: true,
      backgroundColor: "rgba(0, 0, 0, .8)",
    },
    xAxis: [
      {
        type: "category",
        data: data.map((i) => {
          return new Date(i.anno, i.mese, 0).toLocaleDateString("it-IT", {
            // day: "2-digit",
            month: "long",
          });
        }),
        // data: data.map((i) =>
        //   new Date(i.data).toLocaleDateString("it-IT", {
        //     day: "2-digit",
        //     month: "2-digit",
        //   })
        // ),
        axisTick: {
          alignWithLabel: true,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          show: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: "{value}",
        },
        min: 0,
        max: Math.max(...data.map((o) => o.utenti)),
        interval: 10,
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          interval: "auto",
        },
      },
    ],

    series: [
      // {
      //   name: "Pianificati",
      //   // data: data.map((i) => i.pianificati),
      //   label: { show: false, color: "#0168c1" },
      //   type: "line",
      //   barGap: 0,
      //   color: "#bcbbdd",
      //   smooth: true,
      //   itemStyle: {
      //     emphasis: {
      //       shadowBlur: 10,
      //       shadowOffsetX: 0,
      //       shadowOffsetY: -2,
      //       shadowColor: "rgba(0, 0, 0, 0.3)",
      //     },
      //   },
      // },
      {
        name: "Utenti",
        data: data.map((i) => i.utenti),
        label: { show: false, color: "#639" },
        type: "line",
        color: "#7569b3",
        smooth: true,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: -2,
            shadowColor: "rgba(0, 0, 0, 0.3)",
          },
        },
      },
    ],
  };

  return <ReactEcharts style={{ height: height }} option={option} />;
};

export default ProjectsChart;
