import _ from 'lodash';
import { useEffect } from 'react';
import { useDataRequest } from '@ai4/data-request';
import RecordsManagement, { Formatters } from '@ai4/records-management';
import { Stack } from 'react-bootstrap';

const ACCESS_LOG_MAX = 50;

const AccessLogs = () => {
    const { useRestRequest } = useDataRequest();
	const [reqList, resList] = useRestRequest({
        path: 'api/{ver}/personal/access-logs',
        method: 'GET',
        jwt: true,
		queryString: {
			pageNumber: '1',
			pageSize: `${ACCESS_LOG_MAX}`,
		},
    });
    useEffect(() => {
        reqList();
    }, []);

	const rows = resList.data || [];

	const args = {
		module,
		entity: 'access-logs',
		columns: [
			{
				dataField: 'accessDate',
				text: 'Accesso',
				formatter: Formatters.dateFormatter
			},
			{
				dataField: 'loginSuccess',
				text: 'Esito',
				formatter: (cell: any) => (cell ? 'positivo' : 'negativo'),
			},
		],
		rows: rows,
		dataLoading: resList.loading,
		formSchema: {},
		submitResponse: null,
		hideEditColumns: true,
		slots: {
			header: ({ onCreate }) => {
				return <div>
					<h2>{`Ultimi ${ACCESS_LOG_MAX} accessi`}</h2>
				</div>
			},
		},
		onBoot: () => {},
		onInit: () => {},
		onChange: () => {},
		onSubmit: () => {},
		onCancel: () => {},
		onSelectedRow: () => {},
		onDeleteRow: () => {},
		onAddExtraData: () => {},
	}
	return <>
		{/* @ts-ignore */}
		<RecordsManagement
			{...args}
			
		/>
	</>
}

export default AccessLogs;