import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const prenotazioniRoutes = [
  {
    path: "prenotazioni/List",
    element: <Prenotazioni />,
    // auth: authRoles.admin
  },
];

function Prenotazioni() {
  return <Crud module="prenotazioni" entity="prenotazioni" />;
}

export default prenotazioniRoutes;
