import React, { useContext } from 'react';
import Root from 'src/@bootstrap/Root';
import "src/app/store";
import routes from "src/app/routes";
import theme from "src/app/theme";
import "src/app/styles/index.scss";
import { ConfigContext } from 'src/@bootstrap/Config';

function App() {
    const { getEnv } = useContext(ConfigContext);
    
    return (
        <Root
            routes={routes}
            theme={theme}
            dataProvider={{
                domain: 'default',
                domains: {
                    default: {
                        base: getEnv("REACT_APP_API_BASE") || "",
                        xAPIKey: getEnv("REACT_APP_API_XAPIKEY") || "",
                    },
                },
            }}
        />
    );
}

export default App;
