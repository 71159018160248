import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const utentiRoutes = [
  {
    path: "utenti/List",
    element: <Utenti />,
    // auth: authRoles.admin
  },
];

function Utenti() {
  return <Crud module="utenti" entity="utenti" />;
}

export default utentiRoutes;
