import _get from "lodash/get";
import { useMemo, useState, useEffect } from "react";
import { getEditFormViewer } from "../common";
import Edit from "src/app/components/Edit";
import { FormViewer } from "@ai4/form-viewer";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import TitleBar from "src/app/components/TitleBar";

export function EditOpere(props: any) {
  const { record, onInit, onAddExtraData, handleClose } = props;

  const formArgs = useMemo(() => {
    const { initialValues, ...args } = getEditFormViewer(props);
    return {
      ...args,
      initialValues: {
        ...initialValues,
      },
    };
  }, [props]);

  const [extraData, setExtraData] = useState({
    resoIl: _get(record, "resoIl", undefined) || undefined,
  });

  useEffect(() => {
    onAddExtraData({
      ...extraData,
    });
  }, [extraData]);

  const [showDetailRow, setShowDetailRow] = useState(false);
  const [rowData, setRowData] = useState<
    {
      highLightPrenotazione: boolean;
      highLightPrestito: boolean;
      prenotatoDal: any;
      prenotatoAl: any;
      prestatoDal: any;
      prestatoAl: any;
    }[]
  >([]);

  const handleCloseDetailRow = () => setShowDetailRow(false);
  const handleShowDetailRow = () => setShowDetailRow(true);

  const listToTableData = (listaPrenotazioni, listaPrestiti) => {
    //prendo i più recenti
    let latestPrenotazione = null;
    let latestPrestito = null;

    if (listaPrenotazioni.length > 0) {
      latestPrenotazione = listaPrenotazioni.reduce(function (prev, current) {
        return prev.insertDate > current.insertDate ? prev : current;
      });
    }
    if (listaPrestiti.length > 0) {
      latestPrestito = listaPrestiti.reduce(function (prev, current) {
        return prev.insertDate > current.insertDate ? prev : current;
      });
    }

    const rowsNumber = Math.max(listaPrenotazioni.length, listaPrestiti.length);
    let rows: {
      highLightPrenotazione: boolean;
      highLightPrestito: boolean;
      prenotatoDal: any;
      prenotatoAl: any;
      prestatoDal: any;
      prestatoAl: any;
    }[] = [];
    for (let index = 0; index < rowsNumber; index++) {
      const prenotazione = listaPrenotazioni[index];
      const prestito = listaPrestiti[index];

      const { prenotatoAl, prenotatoDal } = prenotazione || {};
      const { prestatoAl, prestatoDal } = prestito || {};

      const rowData = {
        highLightPrenotazione: prenotazione === latestPrenotazione,
        highLightPrestito: prestito === latestPrestito,
        prenotatoDal: prenotatoDal
          ? new Date(prenotatoDal).toLocaleDateString("en-GB")
          : "",
        prenotatoAl: prenotatoAl
          ? new Date(prenotatoAl).toLocaleDateString("en-GB")
          : "",
        prestatoDal: prestatoDal
          ? new Date(prestatoDal).toLocaleDateString("en-GB")
          : "",
        prestatoAl: prestatoAl
          ? new Date(prestatoAl).toLocaleDateString("en-GB")
          : "",
      };

      rows.push(rowData);
    }
    setRowData(rows);
  };

  const tableData = rowData.map((item, idx) => {
    const highlightedRowStyle = { backgroundColor: "rgba(0,0,0,0.05)" };
    const nonHighlightedRowStyle = { backgroundColor: "rgba(255,255,255)" };
    return (
      // style={}
      <tr>
        <th>{idx + 1}</th>
        <td
          style={
            item.highLightPrenotazione
              ? highlightedRowStyle
              : nonHighlightedRowStyle
          }
        >
          {item.prenotatoDal}
        </td>
        <td
          style={
            item.highLightPrenotazione
              ? highlightedRowStyle
              : nonHighlightedRowStyle
          }
        >
          {item.prenotatoAl}
        </td>
        <td
          style={
            item.highLightPrestito
              ? highlightedRowStyle
              : nonHighlightedRowStyle
          }
        >
          {item.prestatoDal}
        </td>
        <td
          style={
            item.highLightPrestito
              ? highlightedRowStyle
              : nonHighlightedRowStyle
          }
        >
          {item.prestatoAl}
        </td>
      </tr>
    );
  });

  return (
    <Edit>
      <TitleBar
        path={[`${record.uniqueId ? record.titolo : "Nuova opera"}`]}
        onBack={handleClose}
      />
      <FormViewer
        {...formArgs}
        onInit={(form) => {
          if (onInit) onInit(form);
          //se è la modifica di un'opera mostro i dettagli
          if (form.values.uniqueId) {
            listToTableData(form.values.prenotazioni, form.values.prestiti);

            handleShowDetailRow();
          } else {
            handleCloseDetailRow();
          }
        }}
        // onChange={(form) => {
        //controllare anche che nel caso si resetti il campo
        //   if (
        //     !record.uniqueId &&
        //     form.changedValues.hasOwnProperty("operaUniqueId")
        //   ) {
        //     operaState(formArgs.rows, form.changedValues.operaUniqueId);
        //   }
        // }}
      />
      {showDetailRow ? (
        <>
          <Table bordered>
            <thead>
              <tr>
                <th colSpan={3}>Prenotazioni</th>
                <th colSpan={2}>Prestiti</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>#</th>
                <td>Prenotato Dal</td>
                <td>Prenotato Al</td>
                <td>Prestato Dal</td>
                <td>Prestato Al</td>
              </tr>

              {tableData}
            </tbody>
          </Table>
          {/* <Container> */}

          {/* <Row>
              <Col>{"prenotato fino al: " + prenotatoAl}</Col>
            </Row> */}
          {/* </Container> */}
        </>
      ) : (
        ""
      )}
      {/* POPUP */}
      {/* <Modal show={showodal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title> }
        </Modal.Header>
        <Modal.Body>Confermi il reso?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annulla
          </Button>
          <Button variant="primary" onClick={handleSaveExtraData}>
            Conferma
          </Button>
        </Modal.Footer>
        <Modal.Footer></Modal.Footer>
      </Modal> */}

      {/* BOTTONE CHE FA COMPARIRE IL POPUP */}
      {/* <Button style={{}} onClick={handleShowodal}>
        Esegui Reso
      </Button> */}
    </Edit>
  );
}

export default EditOpere;
