import _get from "lodash/get";
import { SchemaTypes } from "@ai4/form-viewer";
import $schema from "./utenti.schema.json";
import {
  filterDefault,
  pathToSlug,
} from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import { isMobile } from "react-device-detect";

import { textFilter } from "@ai4/react-bootstrap-table2-filter";

let schema = $schema as SchemaTypes.Schema;

// export const QUERY = gql`
//   query getUtenti {
//     bibliotecaQuery {
//       utenti {
//         list {
//           uniqueId
//           nome
//           cognome
//           email
//           attivo
//         }
//       }
//     }
//   }
// `;

export const SAVE = gql`
  mutation addUtente($data: UtenteInput) {
    bibliotecaMutation {
      utente {
        salva(utente: $data) {
          uniqueId
          nome
          cognome
          email
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation deleteUtente($uniqueId: String) {
    bibliotecaMutation {
      utente {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = () => {
  const list = "bibliotecaQuery.utenti.list";
  const mutation = "bibliotecaMutation.utente";
  const { useLazyQuery, useQuery, useMutation } = useDataRequest();
  // const query = useLazyQuery(QUERY);
  // const [execDetails, { data: dataDetails, loading }] = query;
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });

  return {
    graphql: {
      list,
      mutation,
      // loading,
      save: execSave,
      remove: execDelete,
      // details: execDetails,
    },
    schema,
    results: {
      save: resSave,
      remove: resDelete,
    },
    // dataDetails,
    extra: {
      options: {
        closeWhenSaved: true,
      },
    },
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "nome",
          text: "Nome",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "cognome",
          text: "Cognome",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "email",
          text: "Email",
          filter: textFilter(filterDefault()),
        },
        // {
        //   dataField: "attivo",
        //   text: "Attivo",
        //   hidden: isMobile,
        //   sort: true,
        //   headerStyle: { width: "100px" },
        //   formatter: (cell, row, rowIndex, formatExtraData) => {
        //     return (
        //       <Form.Check
        //         type="switch"
        //         checked={row.attivo}
        //         onChange={() => {}}
        //         label=""
        //       />
        //     );
        //   },
        // },
      ],
      dateNascoste: isMobile,
    },
  };
};
