import _set from "lodash/set";
import _get from "lodash/get";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDataRequest } from "@ai4/data-request";
import { GET_RESO, RESO_RELATED } from "./gql/reso";
import { FormViewer } from "@ai4/form-viewer";
import { useSelectItemsPopulate } from "src/@bootstrap/components/Crud/data";
import $schema from "./resi.schema.json";
import Schema from "@ai4/form-viewer/dist/types/schema";

const ResiDettaglio = () => {
  const { id } = useParams();
  const { useQuery } = useDataRequest();

  const query = useQuery(GET_RESO, {
    variables: {
      id,
    },
  });
  const related = useQuery(RESO_RELATED);

  const data = _get(query.data, "bibliotecaQuery.prestiti.list", []);
  const record = useMemo(() => {
    return data.length > 0 ? data[0] : {};
  }, [data]);

  useSelectItemsPopulate($schema as unknown as any, related, {
    operaUniqueId: [
      ..._get(related.data, "bibliotecaQuery.opere.list", []).map(
        (cat: any) => ({
          text: cat.titolo,
          value: cat.uniqueId,
        })
      ),
    ],
    utenteUniqueId: [
      ..._get(related.data, "bibliotecaQuery.utenti.list", []).map(
        (cat: any) => ({
          text: cat.nome + " " + cat.cognome + " (" + cat.email + ")",
          value: cat.uniqueId,
        })
      ),
    ],
  });

  if (!record || !record.uniqueId) return null;
  return (
    <div>
      <h1>
        Reso di {record.opera.titolo} di {record.opera.autore.nome}
      </h1>

      <FormViewer
        initialValues={record}
        schema={$schema as Schema}
        slots={{
          ButtonBar: (args) => <></>,
        }}
      />
    </div>
  );
};

export default ResiDettaglio;
