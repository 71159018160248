import { lazy } from "react";
import { authRoles } from "src/app/roles";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const tenantsRoutes = [
    {
        path: "tenants/List",
        element: <Tenants />,
        auth: authRoles.admin
    },
];

function Tenants() {
    return <Crud module='@tenants' entity='tenants' />;
};

export default tenantsRoutes;
