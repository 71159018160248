import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/@bootstrap/services/store';
import { GullLayoutSettings } from "src/app/template/settings";

interface LayoutSettings {
    activeLayout: string;
    dir: string; // ltr, rtl
    layout1Settings: any;
    layout2Settings: any;
    layout3Settings: any;
    customizer: {
        show: boolean,
        open: boolean
    },
    footer: {
        show: boolean
    }
}

interface LayoutState {
    settings: LayoutSettings,
    defaultSettings: LayoutSettings
};

const initialState: LayoutState = {
    settings: {
        ...GullLayoutSettings
    },
    defaultSettings: {
        ...GullLayoutSettings
    }
};

export const layoutReducer = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setLayoutSettings: (state, action: PayloadAction<LayoutSettings>) => {
            state.settings = { ...action.payload };
        },
        setDefaultSettings: (state, action: PayloadAction<LayoutSettings>) => {
            state.defaultSettings = { ...action.payload };
        },
    },
});

export const { setLayoutSettings, setDefaultSettings } = layoutReducer.actions;

export const selectSettings = (state: RootState) => state.layout.settings;
export const selectDefaultSettings = (state: RootState) => state.layout.defaultSettings;

export default layoutReducer.reducer
