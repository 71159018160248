import { useState } from "react";

interface Props {
    data: any[];
    ipp: number;
    head: () => JSX.Element;
    row: (item: any, index: number) => JSX.Element;
  }

function TableWidget(props: Props) {
    const { data, ipp, head, row } = props;
    const [page, setPage] = useState(0);

    const pages = Math.ceil(data.length / ipp);

    return <div className="ul-widget-body">
        <div className="ul-widget3">
        <div className="ul-widget6__item--table">
            <table className="table">
            {head()}
            <tbody>
            {data
            .slice(
                page * ipp,
                (page + 1) * ipp
            )
            .map((item, i) => <>{row(item, i)}</>)}
            </tbody>
            </table>
        </div>
        {pages > 1 && <nav aria-label="Navigazione pagine">
            <ul className="pagination">
                {page > 0 && <li className="previous"><a tabIndex={0} role="button" onClick={() => setPage(page-1)}>Prec.</a></li>}
                {page <= 0 && <li className="previous disabled"><a aria-disabled="true">Prec.</a></li>}
                {Array.from(Array(pages).keys()).map(p => <li className={page === p ? 'active' : ''}><a role="button" tabIndex={0} onClick={() => setPage(p)}>{p+1}</a></li>)}
                {page < pages-1 && <li className="next"><a tabIndex={0} role="button" aria-disabled="false" onClick={() => setPage(page+1)}>Succ.</a></li>}
                {page >= pages-1 && <li className="next disabled"><a aria-disabled="true">Succ.</a></li>}
            </ul>
        </nav>}
        </div>
    </div>;
}

export default TableWidget;