import { buildStore } from 'src/@bootstrap/services/store';
import appReducer from 'src/@bootstrap/features/app';
import authReducer from 'src/@bootstrap/features/auth';
import layoutReducer from 'src/@bootstrap/features/layout';
import customReducer from 'src/app/features/custom';

buildStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        layout: layoutReducer,
        custom: customReducer,
    },
});