import { gql } from "@ai4/data-request";

export const GET_PRESTITO = gql`
  query getPrestiti($id: Guid!) {
    bibliotecaQuery {
      prestiti {
        list(where: { uniqueId: { _eq: $id } }) {
          uniqueId
          opera {
            uniqueId
            titolo
            stato
            autore {
              uniqueId
              nome
            }
          }
          utente {
            uniqueId
            nome
          }
          prestatoDal
          prestatoAl
          resoIl
          operaUniqueId
          utenteUniqueId
        }
      }
    }
  }
`;
export const RESO_RELATED = gql`
  query getRelated {
    bibliotecaQuery {
      opere {
        list {
          uniqueId
          titolo
          stato
        }
      }
      utenti {
        list {
          uniqueId
          nome
          cognome
          email
        }
      }
    }
  }
`;
