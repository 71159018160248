class Avatar {
    static getCanvasContext = (canvas) => {
        return canvas.getContext("2d");
    };

    static generate = (name, backgroundColor, textColor) => {
        if (!backgroundColor) backgroundColor = "#ccc";

        if (!textColor) textColor = "#999";

        var canvas = document.createElement("canvas");
        canvas.style.display = "none";
        canvas.width = 48;
        canvas.height = 48;
        document.body.appendChild(canvas);
        var context = Avatar.getCanvasContext(canvas);
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = "20px Arial";
        context.fillStyle = textColor;
        let nameSurname = name.split(" ");
        var first, last;
        if (
            nameSurname.length > 0 &&
            nameSurname[0] !== "" &&
            nameSurname[0] !== null
        ) {
            first = nameSurname[0][0];
            last =
                nameSurname.length > 1 &&
                    nameSurname[1] !== "" &&
                    nameSurname[1] !== null
                    ? nameSurname[1][0]
                    : null;
            let sideMargin = 12;
            var initials = null;
            if (last) {
                initials = first + last;
            } else {
                initials = first;
                sideMargin = 18;
            }
            context.fillText(
                (initials! as string).toUpperCase(),
                sideMargin,
                31,
                48 - sideMargin * 2
            );

            var data = canvas.toDataURL();
            document.body.removeChild(canvas);
            return data;
        } else {
            return undefined;
        }
    };

    static getUrlParam = (param) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(param);
    }
}
export default Avatar;