import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";
import OpereDettaglio from "./OpereDettaglio";

const opereRoutes = [
  {
    path: "opere/List",
    element: <Opere />,
    // auth: authRoles.admin
  },
  {
    path: "opere/dettaglio/:id",
    element: <OpereDettaglio />,
    // auth: authRoles.admin
  },
];

function Opere() {
  return <Crud module="opere" entity="opere" />;
}

export default opereRoutes;
