import React, { Suspense, useMemo } from "react";
import { Theme } from "src/@bootstrap/types";

const theme: Theme = {
  header: {
    logo: "/assets/images/biblio.png",
  },
  footer: {
    copyright: "&copy; AI4Smartcity",
    notes: "All rights reserved",
  },
};

export default theme;