import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const autoriRoutes = [
  {
    path: "autori/List",
    element: <Autori />,
    // auth: authRoles.admin
  },
];

function Autori() {
  return <Crud module="autori" entity="autori" />;
}

export default autoriRoutes;
