import { lazy } from "react";
import Guest from "src/@bootstrap/auth/Guest";

const Signup = lazy(() => import("./Signup"));

const Signin = lazy(() => import("./Signin"));

const ChangePassword = lazy(() => import("./ChangePassword"));

const Error404 = lazy(() => import("./Error"));

const sessionsRoutes = [
  {
    path: "/session/signup",
    element: <Guest><Signup /></Guest>
  },
  {
    path: "/session/signin",
    element: <Guest><Signin /></Guest>
  },
  {
    path: "/session/change-password",
    element: <Guest><ChangePassword /></Guest>
  },
  {
    path: "/session/404",
    element: <Guest><Error404 /></Guest>
  }
];

export default sessionsRoutes;
