import React from "react";
import {
    useLocation,
    useNavigate,
    useParams,
    useRoutes,
} from "react-router-dom";
import { flatMap } from "lodash";
import { Route } from "src/@bootstrap/types";

/****
 * withRouter is @deprecated
 * https://reactrouter.com/docs/en/v6/faq
 */
export function withRouter(Component: any) { // React.ComponentClass
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                { ...props }
                router = {{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

function normalPath(path: string | undefined) {
    if (!path) path = '/';
    if (path.substring(0, 1) !== '/') path = `/${path}`;
    return path;
}

export function matchRouteByPath(routes: Route[], path: string) {
    const all = flatMap(routes, route => route.children ? [route, ...route.children] : [route]);
    return all.find(r => normalPath(r.path) === normalPath(path));
}

export function matchRouteByID(routes: Route[], id: string) {
    const all = flatMap(routes, route => route.children ? [route, ...route.children] : [route]);
    return all.find(r => r.id === id);
}

export function RenderRoutes(props: { routes: Route[] }) {
  return useRoutes(props.routes);
}