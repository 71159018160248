import React, { useState, useCallback, Fragment, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useDataRequest } from "@ai4/data-request";
import { User } from "src/@bootstrap/types";
import { AppContext } from "src/@bootstrap/context";
import GullLayout from "src/@bootstrap/template/@gull/layout/GullLayout";
import { matchRouteByPath } from "src/@bootstrap/services/router";
import { loggedIn, loggedOut, selectAuthenticated, selectCurrentUser } from "src/@bootstrap/features/auth";
import Messages from "src/@bootstrap/template/partials/Messages";
import Signin from "../views/sessions/Signin";

function AuthGuard() {
    const [isJustOpened, setIsJustOpened] = useState(true);
    const [checkingRoute, setCheckingRoute] = useState(false);
    const [checkingToken, setCheckingToken] = useState(false);
    const { routes } = useContext(AppContext);
    const navigate = useNavigate();
    let location = useLocation();
    const auth = useSelector(selectAuthenticated);
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const { useRestRequest } = useDataRequest();
    const [reqUser, ] = useRestRequest({
        path: 'api/{ver}/personal/profile',
        method: 'GET',
        jwt: true,
    });

    const [reqPerm, ] = useRestRequest({
        path: 'api/{ver}/personal/permissions',
        method: 'GET',
        jwt: true,
    });

    const redirectRoute = useCallback(() => {
        dispatch(loggedOut());
        navigate("/session/signin", {
            state: { redirectUrl: location.pathname }
        });
    }, [location.pathname]);

    const loadUser = useCallback(async () => {
        try {
            const user = await reqUser();
            const permissions = await reqPerm();
            dispatch(
                loggedIn({
                    ...(user as User),
                    role: "SA",
                    permissions,
                })
            );
        } catch (e) {
            redirectRoute();
        }
    }, []);

    const checkToken = useCallback(async () => {
        setCheckingToken(true);
        try {
            const user = await reqUser();
        } catch (e) {
            // redirectRoute();
        } finally {
            setCheckingToken(false);
        }
    }, []);

    /*** recognize a bad token */
    useEffect(() => {
        const listener = (e) => {
            if (auth) {
                checkToken();
            }
        };
        if (auth) {
            window.addEventListener("focus", listener, false);
        }
        return () => {
            window.removeEventListener("focus", listener);
        }
    }, [auth])

    useEffect(() => {
        (async () => {
            if (auth) {
                loadUser();
            }
        })();
    }, [auth]);

    // check only for first appearance
    useEffect(() => {
        if (!isJustOpened) return;
        setCheckingRoute(true);
        // find if user has authorization for this route
        const matched = matchRouteByPath(routes, location.pathname);
        const authenticated =
            matched && matched.auth && matched.auth.length
                ? (user ? matched.auth.includes(user.role) : false)
                : true;
        if (!authenticated) {
            redirectRoute();
        } else {
            setCheckingRoute(false);
            setIsJustOpened(false);
        }
    }, [user, isJustOpened, location.pathname, redirectRoute]);

    if (checkingRoute) return null;
    return (
        <Fragment>
            {checkingToken && <div className="CheckTokenLoader"><Spinner animation="grow" variant="secondary" /></div>}
            {!auth && <Signin inModal />}
            <Messages />
            <GullLayout />
        </Fragment>
    );
}

export default AuthGuard;