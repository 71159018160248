/***
 * @template
 * warning: this file exists in base repo, check for wrong overrides
 */

import React from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "src/@bootstrap/auth/AuthGuard";
import sessionsRoutes from "src/@bootstrap/views/sessions/sessionsRoutes";
import toolsRoutes from "src/@bootstrap/views/tools/toolsRoutes";
import configRoutes from "src/@bootstrap/views/config/configRoutes";
import demoRoutes from "src/app/views/@demo/demoRoutes";
import accountRoutes from "src/@bootstrap/views/account/accountRoutes";
import usersRoutes from "src/app/views/@users/usersRoutes";
import tenantsRoutes from "src/app/views/@tenants/tenantsRoutes";
import { authRoles } from "src/app/roles";

import generiRoutes from "src/app/views/generi/generiRoutes";
import autoriRoutes from "./views/autori/autoriRoutes";
import pianiRoutes from "./views/piani/pianiRoutes";
import utentiRoutes from "./views/utenti/utentiRoutes";
import scaffaliRoutes from "./views/scaffali/scaffaliRoutes";
import opereRoutes from "./views/opere/opereRoutes";
import prenotazioniRoutes from "./views/prenotazioni/prenotazioniRoutes";
import prestitiRoutes from "./views/prestiti/prestitiRoutes";
import tipologieOpereRoutes from "./views/tipologieOpere/tipologieOpereRoutes";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import resiRoutes from "./views/resi/resiRoutes";

const indexRoute = [
    {
        path: "",
        exact: true,
    element: (
      <Navigate replace to="/dashboard/supervisor/DashboardSupervisor" />
    ),
    auth: authRoles.guest,
  },
];

const errorRoute = [
  {
    id: "404",
    path: "404",
    element: <Navigate to="/session/404" />,
  },
];

const routes = [
    ...sessionsRoutes,
    {
        id: 'root',
        path: "/*",
        element: <AuthGuard />,
        children: [
            ...indexRoute,
            ...errorRoute,
            ...toolsRoutes,
            ...configRoutes,
            ...accountRoutes,
            ...usersRoutes,
            ...tenantsRoutes,

            ...generiRoutes,
            ...autoriRoutes,
            ...pianiRoutes,
            ...utentiRoutes,
            ...scaffaliRoutes,
            ...opereRoutes,
            ...prenotazioniRoutes,
            ...prestitiRoutes,
            ...resiRoutes,
            ...dashboardRoutes,
            ...tipologieOpereRoutes,
            
            // to activate demo section uncomment following line
            ...demoRoutes,
        ]
    }
];

export default routes;
