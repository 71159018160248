import { useState } from "react";
import _get from "lodash/get";
import { SchemaTypes } from "@ai4/form-viewer";
import {
  filterDefault,
  pathToSlug,
} from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import { useSelectItemsPopulate } from "src/@bootstrap/components/Crud/data";
import { textFilter } from "@ai4/react-bootstrap-table2-filter";
import { Formatters } from "@ai4/records-management";
import { isMobile } from "react-device-detect";
import EditPrestiti from "./EditPrestiti";

export const PRESTITI = gql`
  query getPrestiti($id: Guid!) {
    bibliotecaQuery {
      prestiti {
        list(where: { uniqueId: { _eq: $id } }) {
          uniqueId
          opera {
            uniqueId
            titolo
            stato
          }
          utente {
            uniqueId
            nome
          }
          prestatoDal
          prestatoAl
          resoIl
        }
      }
    }
  }
`;

export const RELATED = gql`
  query getRelated {
    bibliotecaQuery {
      opere {
        list: list(stato: DISPONIBILE) {
          uniqueId
          titolo
          stato
        }
        opere_prenotate: list(stato: PRENOTATA) {
          uniqueId
          titolo
          stato
          prenotazioni {
            insertDate
            prenotatoAl
            prenotatoDal
            utente {
              nome
              cognome
            }
          }
        }
      }
      utenti {
        list {
          uniqueId
          nome
          cognome
          email
        }
      }
    }
  }
`;

export const SAVE = gql`
  mutation addPrestito($data: PrestitoInput) {
    bibliotecaMutation {
      prestito {
        salva(prestito: $data) {
          uniqueId
          operaUniqueId
          utenteUniqueId
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation deletePrestito($uniqueId: String) {
    bibliotecaMutation {
      prestito {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = () => {
  const list = "bibliotecaQuery.prestiti.list";
  const mutation = "bibliotecaMutation.prestito";
  const { useLazyQuery, useQuery, useMutation } = useDataRequest();
  const related = useQuery(RELATED);
  // const { data, loading } = related;
  const query = useLazyQuery(PRESTITI);
  const [execDetails, { data: dataDetails, loading }] = query;
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [editMode, setEditMode] = useState(false);

  const getOperaUniqueIdValue = () => {
    if (editMode && dataDetails) {
      const operaPrestito = dataDetails.bibliotecaQuery.prestiti.list[0].opera;
      return [
        {
          text: operaPrestito.titolo + " (" + operaPrestito.stato + ")",
          value: operaPrestito.uniqueId,
        },
      ];
    } else {
      let mergedRelatedData;

      if (related.data) {
        const opereDisponibili = related.data.bibliotecaQuery.opere.list;
        const operePrenotate =
          related.data.bibliotecaQuery.opere.opere_prenotate;

        const mergedOpere = opereDisponibili.concat(operePrenotate);
        // mergedRelatedData = { ...related.data };
        // mergedRelatedData = Object.assign({}, related.data);
        mergedRelatedData = JSON.parse(JSON.stringify(related.data));

        mergedRelatedData.bibliotecaQuery.opere = {
          list: mergedOpere,
        };
      }
      return [
        ..._get(mergedRelatedData, "bibliotecaQuery.opere.list", []).map(
          (cat: any) => {
            let textDatePrenotazione = "";
            let textValue = "";
            const stato = cat.stato;
            const prenotazioni = cat.prenotazioni;

            const getHigherDate = (dates) => {
              const higherDate = new Date(Math.max.apply(null, dates));
              return higherDate.toLocaleDateString("en-GB");
            };

            const getHigherPrenotazione = (prenotazioni) => {
              const prenotazioneInsertDate = prenotazioni.map(
                (v) => new Date(v.insertDate)
              );

              const higherDate = new Date(
                Math.max.apply(null, prenotazioneInsertDate)
              );
              const higherPrenotazione = prenotazioni.find((obj) => {
                return (
                  new Date(obj.insertDate).getTime() === higherDate.getTime()
                );
              });
              return higherPrenotazione;
            };

            const getPrenotazioneText = (prenotazione) => {
              return (
                " (PRENOTATA da " +
                prenotazione.utente.nome +
                " " +
                prenotazione.utente.cognome +
                " dal " +
                new Date(prenotazione.prenotatoDal).toLocaleDateString(
                  "en-GB"
                ) +
                " al " +
                new Date(prenotazione.prenotatoAl).toLocaleDateString("en-GB") +
                ")"
              );
            };

            textValue = cat.titolo + " (" + cat.stato + ")";

            switch (stato) {
              case "DISPONIBILE":
                break;
              case "PRENOTATA":
                textValue = cat.titolo;

                if (prenotazioni.length > 1) {
                  const prenotazione = getHigherPrenotazione(prenotazioni);
                  textDatePrenotazione = getPrenotazioneText(prenotazione);
                  // const prenotazioneInsertDate = prenotazioni.map(
                  //   (v) => new Date(v.insertDate)
                  // );
                  // textDatePrenotazione += getHigherDate(prenotazioneInsertDate);
                } else if (prenotazioni.length === 1) {
                  const prenotazione = prenotazioni[0];
                  textDatePrenotazione = getPrenotazioneText(prenotazione);
                } else {
                  textDatePrenotazione = "";
                }

                break;
              default:
                break;
            }
            return {
              text: textValue + textDatePrenotazione,
              value: cat.uniqueId,
            };
          }
        ),
      ];
    }
  };

  const onInit = (args: any) => {
    const { values, schema, getSchemaNode, setSchemaNode } = args;
    const isInEditMode = values.uniqueId ? true : false;
    setEditMode(isInEditMode);
  };

  var schema = require("./prestiti.schema.json");
  schema = useSelectItemsPopulate(schema as SchemaTypes.Schema, related, {
    operaUniqueId: getOperaUniqueIdValue(),
    utenteUniqueId: [
      ..._get(related.data, "bibliotecaQuery.utenti.list", []).map(
        (cat: any) => ({
          text: cat.nome + " " + cat.cognome + " (" + cat.email + ")",
          value: cat.uniqueId,
        })
      ),
    ],
  });

  return {
    graphql: {
      list,
      mutation,
      save: execSave,
      remove: execDelete,
      details: execDetails,
      listFilters: () => {
        return `list(where: { resoIl:{_eq: null} }, order: {prestatoDal: DESC})`;
      },
    },
    results: {
      save: resSave,
      remove: resDelete,
    },
    schema,
    dataDetails,
    related,
    extra: {
      options: {
        closeWhenSaved: true,
      },
    },
    methods: {
      onFormInit: onInit,
    },
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "utente.nome",
          text: "Nome utente",
          filter: textFilter(filterDefault()),
          hidden: isMobile,
        },
        {
          dataField: "utente.cognome",
          text: "Cognome utente",
          filter: textFilter(filterDefault()),
          hidden: isMobile,
        },
        {
          dataField: "opera.titolo",
          text: "Opera",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "prestatoDal",
          text: "Prestato Dal",
          formatter: Formatters.dateFormatter,
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "prestatoAl",
          text: "Prestato Al",
          formatter: Formatters.dateFormatter,
          filter: textFilter(filterDefault()),
        },
        // {
        //   dataField: "resoIl",
        //   text: "Data Reso",
        //   // filter: selectFilter({
        //   //   ...filterDefault(),
        //   //   options: "uniqueId:resoIl",
        //   // }),
        //   // filterValue: (cell, row) => {
        //   //   return cell ? row.resoIl : "0";
        //   // },
        //   formatter: Formatters.dateFormatter,
        // },
        // {
        //   dataField: "",
        //   formatter: (cell, row) => {
        //     return (
        //       <>
        //         {/* POPUP */}
        //         <Modal show={showodal} onHide={handleCloseModal}>
        //           <Modal.Header closeButton>
        //             {/* <Modal.Title>Modal heading</Modal.Title> */}
        //           </Modal.Header>
        //           <Modal.Body>Confermi il reso?</Modal.Body>
        //           <Modal.Footer>
        //             <Button variant="secondary" onClick={handleCloseModal}>
        //               Annulla
        //             </Button>
        //             <Button variant="primary">Conferma</Button>
        //           </Modal.Footer>
        //           <Modal.Footer></Modal.Footer>
        //         </Modal>

        //         {/* BOTTONE CHE FA COMPARIRE IL POPUP */}
        //         <Button style={{}} onClick={handleShowodal}>
        //           Esegui Reso
        //         </Button>
        //       </>
        //     );
        //   },
        // },
      ],
      dateNascoste: isMobile,
    },
  };
};
