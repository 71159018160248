import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectErrors, selectMessage, setErrors, setMessage } from "src/@bootstrap/features/app";
import { SuccessAlert, ErrorAlert } from "src/@bootstrap/components/alert";

function Messages() {
    const message = useSelector(selectMessage);
    const errors = useSelector(selectErrors);
    const dispatch = useDispatch();

    return (<>
        {message && <SuccessAlert text={message} onClose={() => { dispatch(setMessage()); }} />}
        {errors && <ErrorAlert errors={errors} onClose={() => { dispatch(setErrors()); }} />}
    </>);
}

export default Messages;