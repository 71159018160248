import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const tipologieOpereRoutes = [
  {
    path: "tipologieOpere/List",
    element: <TipologieOpere />,
    // auth: authRoles.admin
  },
];

function TipologieOpere() {
  return <Crud module="tipologieOpere" entity="tipologieOpere" />;
}

export default tipologieOpereRoutes;
