import _get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SchemaTypes, traverseSchema } from "@ai4/form-viewer";
import Schema from "@ai4/form-viewer/dist/types/schema";
import { Button } from 'react-bootstrap';
import { useDataRequest } from "@ai4/data-request";
import { findList } from './views/commons/GestioneListe';
import { manageList, selectLists } from './features/custom';

type SelectItemsMap = Record<string, { text?: string, value?: string, creationURL?: string, listManagement?: { name: string; query_name: string; query_path: string; }, button?: () => JSX.Element }[]>;
interface SelectItemsOptions {
	noRefresh?: boolean;
}

export function useSelectItemsPopulate(schema: Schema | null, query: any, map: SelectItemsMap, options?: SelectItemsOptions) {
	const dispatch = useDispatch();
    const lists = useSelector(selectLists);
	const { data, refetch } = query;
	const { noRefresh } = options || {};
	if (!schema) return null;
    if (!data) return null; // schema;

	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && map[name]) {
			const { creationURL } = map[n.name].find(i => i.creationURL) || {};
			const { listManagement } = map[n.name].find(i => i.listManagement) || {};
			const { button } = map[n.name].find(i => i.button) || {};
			var action;
			if (creationURL) action = `${creationURL}#create`;
			if (listManagement) {
				const list = findList(lists, listManagement.name);
				// if (list.isModificabile) {
					action = () => <Button variant='primary' onClick={() => { dispatch(manageList(listManagement)); }}>Gestisci</Button>;
				// }
			}
			if (button) action = button;
			return {
				...n,
				props: {
					...(n.props || {}),
					items: map[name].filter(i => !!i.value),
					creationURL: action,
					onRefresh: noRefresh ? undefined : () => {
						refetch();
					}
				}
			};
		}
		return n;
	});
}