import React, { useMemo } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { createPortal } from "react-dom";
import { Errors } from "src/@bootstrap/types";

interface Props {
    errors: Errors;
    onClose: () => void;
}

export function ErrorAlert(props: Props) {
    const { errors, onClose } = props;

    const text = useMemo(() => {
        let text = [] as string[];
        if (typeof errors === 'string') {
            text.push(errors);
        } else {
            text = errors.map((error) => {
                if (typeof error === 'string') {
                    return error;
                }
                return error.message;
            });
        }
        return text;
    }, [errors]);

    return createPortal(
        <ToastContainer className="p-3" style={{zIndex: 2000}} position={"bottom-end"}>
          <Toast onClose={onClose} bg='danger' delay={3000} autohide>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Error</strong>
            </Toast.Header>
            <Toast.Body>{text && text.map(text => <div>{text}</div>)}</Toast.Body>
          </Toast>
        </ToastContainer>,
        document.body
    );

}
export default ErrorAlert;

