import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";
import PrestitiDettaglio from "./PrestitiDettaglio";

const prestitiRoutes = [
  {
    path: "prestiti/List",
    element: <Prestiti />,
    // auth: authRoles.admin
  },
  {
    path: "prestiti/dettaglio/:id",
    element: <PrestitiDettaglio />,
    // auth: authRoles.admin
  },
];

function Prestiti() {
  return <Crud module="prestiti" entity="prestiti" />;
}

export default prestitiRoutes;
