import React, { Fragment } from "react";
import Messages from "src/@bootstrap/template/partials/Messages";

function Guest(props) {
    const { children } = props;

    return (
        <Fragment>
            <Messages />
            {children}
        </Fragment>
    );
}

export default Guest;