import _set from "lodash/set";
import _get from "lodash/get";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDataRequest } from "@ai4/data-request";
import { GET_OPERA, OPERA_RELATED } from "./gql/opera";
import { FormViewer } from "@ai4/form-viewer";
import { useSelectItemsPopulate } from "src/@bootstrap/components/Crud/data";
import $schema from "./opere.schema.json";
import Schema from "@ai4/form-viewer/dist/types/schema";

const OpereDettaglio = () => {
  const { id } = useParams();
  const { useQuery } = useDataRequest();

  const query = useQuery(GET_OPERA, {
    variables: {
      id,
    },
  });
  const related = useQuery(OPERA_RELATED);

  const data = _get(query.data, "bibliotecaQuery.opere.list", []);
  const record = useMemo(() => {
    return data.length > 0 ? data[0] : {};
  }, [data]);

  useSelectItemsPopulate($schema as unknown as any, related, {
    genereUniqueId: [
      ..._get(related.data, "bibliotecaQuery.generi.list", []).map(
        (cat: any) => ({
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
    ],
    tipologiaOperaUniqueId: [
      ..._get(related.data, "bibliotecaQuery.tipologieOpere.list", []).map(
        (cat: any) => ({
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
    ],
    autoreUniqueId: [
      ..._get(related.data, "bibliotecaQuery.autori.list", []).map(
        (cat: any) => ({
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
    ],
    pianoUniqueId: [
      ..._get(related.data, "bibliotecaQuery.piani.list", []).map(
        (cat: any) => ({
          // text: `${_get(cat, "piano.nome")}`,
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
    ],
    scaffaleUniqueId: [
      ..._get(related.data, "bibliotecaQuery.scaffali.list", []).map(
        (cat: any) => ({
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
    ],
  });

  if (!record || !record.uniqueId) return null;
  console.log("opera", record);
  return (
    <div>
      <h1>
        {record.titolo} di {record.autore.nome}
      </h1>

      <FormViewer
        initialValues={record}
        schema={$schema as Schema}
        // slots={{
        //   ButtonBar: (args) => <></>,
        // }}
      />
    </div>
  );
};

export default OpereDettaglio;
