import { gql } from "@ai4/data-request";

export const GET_OPERA = gql`
  query getOpere($id: Guid!) {
    bibliotecaQuery {
      opere {
        list(where: { uniqueId: { _eq: $id } }) {
          uniqueId
          titolo
          genere {
            uniqueId
            nome
          }
          autore {
            uniqueId
            nome
          }
          stato
          piano {
            uniqueId
            nome
          }
          scaffale {
            uniqueId
            nome
          }
          tipologia {
            uniqueId
            nome
          }
          tipologiaOperaUniqueId
          edizione
          pagine
          pubblicazione
          lingua
          periodo
          prezzo
          note
          prenotazioni {
            prenotatoDal
            prenotatoAl
            insertDate
          }
          prestiti {
            prestatoDal
            prestatoAl
            insertDate
          }
          genereUniqueId
          autoreUniqueId
          pianoUniqueId
          scaffaleUniqueId
          etichetta
        }
      }
    }
  }
`;
export const OPERA_RELATED = gql`
  query getRelated {
    bibliotecaQuery {
      generi {
        list {
          uniqueId
          nome
        }
      }
      autori {
        list {
          uniqueId
          nome
        }
      }
      piani {
        list {
          uniqueId
          nome
        }
      }
      scaffali {
        list {
          uniqueId
          nome
        }
      }
      tipologieOpere {
        list {
          uniqueId
          nome
        }
      }
    }
  }
`;
