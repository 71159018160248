import { lazy } from "react";
import AccessLogs from "./AccessLogs";
import ChangePassword from "./ChangePassword";
import { authRoles } from "src/app/roles";

const accountRoutes = [
    {
        path: "account/access-logs",
        element: <AccessLogs />,
        // auth: authRoles.admin
    },
    {
        path: "account/change-password",
        element: <ChangePassword />,
        // auth: authRoles.admin
    },
];

export default accountRoutes;
