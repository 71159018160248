import React, { useMemo } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { createPortal } from "react-dom";

interface Props {
    text: string;
    onClose: () => void;
}

export function SuccessAlert(props: Props) {
    const { text, onClose } = props;

    return createPortal(
        <ToastContainer className="p-3" style={{zIndex: 2000}} position={"bottom-end"}>
          <Toast onClose={onClose} bg='success' delay={3000} autohide>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Success</strong>
            </Toast.Header>
            <Toast.Body>{text}</Toast.Body>
          </Toast>
        </ToastContainer>,
        document.body
    );

}
export default SuccessAlert;

