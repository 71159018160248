import { lazy } from "react";
import { authRoles } from "src/app/roles";

const NavigationEditor = lazy(() => import("./NavigationEditor"));
const FormBuilder = lazy(() => import("./FormBuilder"));

const toolsRoutes = [
  {
    path: "tools/navigation-editor",
    element: <NavigationEditor />,
    auth: authRoles.editor
  }, 
  {
    path: "tools/form-builder",
    element: <FormBuilder />,
    auth: authRoles.editor
  }
];

export default toolsRoutes;
