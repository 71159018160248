import { lazy } from "react";
import { authRoles } from "src/app/roles";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const demoRoutes = [
    {
        path: "@demo/DemoList",
        element: <Demo />,
        auth: authRoles.admin
    },
];

function Demo() {
    return <Crud module='@demo' entity='demo' />;
};

export default demoRoutes;
