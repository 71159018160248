import _get from "lodash/get";
import { SchemaTypes } from "@ai4/form-viewer";
import {
  filterDefault,
  pathToSlug,
} from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import {
  useFormBuilderSchemaLoader,
  useSelectItemsPopulate,
} from "src/@bootstrap/components/Crud/data";
import { Formatters } from "@ai4/records-management";
import { isMobile } from "react-device-detect";
import { textFilter } from "@ai4/react-bootstrap-table2-filter";

export const PRENOTAZIONI = gql`
  query getPrenotazioni($id: Guid!) {
    bibliotecaQuery {
      prenotazioni {
        list(where: { uniqueId: { _eq: $id } }) {
          uniqueId
          opera {
            uniqueId
            titolo
            stato
          }
          utente {
            uniqueId
            nome
          }
          prenotatoAl
          prenotatoDal
        }
      }
    }
  }
`;

export const RELATED = gql`
  query getRelated {
    bibliotecaQuery {
      opere {
        list {
          uniqueId
          titolo
          stato
          prenotazioni {
            prenotatoAl
          }
          prestiti {
            prestatoAl
          }
        }
      }
      utenti {
        list {
          uniqueId
          nome
          cognome
          email
        }
      }
    }
  }
`;

export const SAVE = gql`
  mutation addPrenotazione($data: PrenotazioneInput) {
    bibliotecaMutation {
      prenotazione {
        salva(prenotazione: $data) {
          uniqueId
          operaUniqueId
          utenteUniqueId
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation deletePrenotazione($uniqueId: String) {
    bibliotecaMutation {
      prenotazione {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = () => {
  const list = "bibliotecaQuery.prenotazioni.list";
  const mutation = "bibliotecaMutation.prenotazione";
  const { useLazyQuery, useQuery, useMutation } = useDataRequest();
  const related = useQuery(RELATED);
  // const { data, loading } = related;
  const query = useLazyQuery(PRENOTAZIONI);
  const [execDetails, { data: dataDetails, loading }] = query;
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });

  var schema = require("./prenotazioni.schema.json");
  schema = useSelectItemsPopulate(schema as SchemaTypes.Schema, related, {
    operaUniqueId: [
      ..._get(related.data, "bibliotecaQuery.opere.list", []).map(
        (cat: any) => {
          let textValue = "";
          let textDate = "";
          const stato = cat.stato;
          const prenotazioni = cat.prenotazioni;
          const prestiti = cat.prestiti;

          const getHigherDate = (dates) => {
            const higherDate = new Date(Math.max.apply(null, dates));
            return higherDate.toLocaleDateString("en-GB");
          };

          textValue = cat.titolo + " (" + cat.stato + ")";

          switch (stato) {
            case "DISPONIBILE":
              break;
            case "PRENOTATA":
              textDate = " prenotato fino al ";

              if (prenotazioni.length > 1) {
                const prenotatoAlArray = prenotazioni.map(
                  (v) => new Date(v.prenotatoAl)
                );

                textDate += getHigherDate(prenotatoAlArray);
              } else if (prenotazioni.length === 1) {
                textDate += new Date(
                  prenotazioni[0].prenotatoAl
                ).toLocaleDateString("en-GB");
              } else {
                textDate = "";
              }
              break;
            case "IN_PRESTITO":
              textDate = " prestato fino al ";

              if (prestiti.length > 1) {
                const prestatoAlArray = prestiti.map(
                  (v) => new Date(v.prestatoAl)
                );
                textDate += getHigherDate(prestatoAlArray);
              } else if (prestiti.length === 1) {
                textDate += new Date(prestiti[0].prestatoAl).toLocaleDateString(
                  "en-GB"
                );
              } else {
                textDate = "";
              }
              break;
            default:
              break;
          }
          return {
            text: textValue + textDate,
            value: cat.uniqueId,
          };
        }
      ),
    ],
    utenteUniqueId: [
      ..._get(related.data, "bibliotecaQuery.utenti.list", []).map(
        (cat: any) => ({
          text: cat.nome + " " + cat.cognome + " (" + cat.email + ")",
          value: cat.uniqueId,
        })
      ),
    ],
  });

  return {
    graphql: {
      list,
      mutation,
      save: execSave,
      remove: execDelete,
      details: execDetails,
      listFilters: () => {
        return `list(order: {prenotatoDal: DESC})`;
      },
    },
    results: {
      save: resSave,
      remove: resDelete,
    },
    schema,
    dataDetails,
    extra: {
      // slots: {
      //   edit: EditPrenotazioni,
      // },
      options: {
        closeWhenSaved: true,
      },
    },
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "opera.titolo",
          text: "Opere",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "utente.email",
          text: "Utente",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "opera.stato",
          text: "Stato",
          hidden: true,
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "prenotatoDal",
          text: "Inizio prenotazione",
          formatter: Formatters.dateFormatter,
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "prenotatoAl",
          text: "Fine prenotazione",
          formatter: Formatters.dateFormatter,
          filter: textFilter(filterDefault()),
        },
      ],
      dateNascoste: isMobile,
    },
  };
};
