import { useState } from "react";
import _get from "lodash/get";
import { SchemaTypes } from "@ai4/form-viewer";
import {
  filterDefault,
  pathToSlug,
} from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import {
  useFormBuilderSchemaLoader,
  useSelectItemsPopulate,
} from "src/@bootstrap/components/Crud/data";
import { Formatters } from "@ai4/records-management";
import { textFilter, selectFilter } from "@ai4/react-bootstrap-table2-filter";
import { Button, Modal } from "react-bootstrap";
import EditResi from "./EditResi";
import { isMobile } from "react-device-detect";

export const PRESTITI = gql`
  query getPrestiti($id: Guid!) {
    bibliotecaQuery {
      prestiti {
        list(where: { uniqueId: { _eq: $id } }) {
          uniqueId
          opera {
            uniqueId
            titolo
            stato
          }
          utente {
            uniqueId
            nome
          }
          prestatoDal
          prestatoAl
          resoIl
        }
      }
    }
  }
`;

export const RELATED = gql`
  query getRelated {
    bibliotecaQuery {
      opere {
        list(stato: DISPONIBILE) {
          uniqueId
          titolo
          stato
        }
      }
      utenti {
        list {
          uniqueId
          nome
          cognome
          email
        }
      }
    }
  }
`;

export const SAVE = gql`
  mutation addPrestito($data: PrestitoInput) {
    bibliotecaMutation {
      prestito {
        salva(prestito: $data) {
          uniqueId
          operaUniqueId
          utenteUniqueId
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation deletePrestito($uniqueId: String) {
    bibliotecaMutation {
      prestito {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = () => {
  const list = "bibliotecaQuery.prestiti.list";
  const mutation = "bibliotecaMutation.prestito";
  const { useLazyQuery, useQuery, useMutation } = useDataRequest();
  const related = useQuery(RELATED);
  // const { data, loading } = related;
  const query = useLazyQuery(PRESTITI);
  const [execDetails, { data: dataDetails, loading }] = query;
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });

  const getOperaUniqueIdValue = () => {
    if (dataDetails) {
      const operaPrestito = dataDetails.bibliotecaQuery.prestiti.list[0].opera;
      return [
        {
          text: operaPrestito.titolo, //+ " (" + operaPrestito.stato + ")"
          value: operaPrestito.uniqueId,
        },
      ];
    } else {
      return [
        ..._get(related.data, "bibliotecaQuery.opere.list", []).map(
          (cat: any) => ({
            text: cat.titolo, //+ " (" + cat.stato + ")"
            value: cat.uniqueId,
          })
        ),
      ];
    }
  };

  var schema = require("./resi.schema.json");
  schema = useSelectItemsPopulate(schema as SchemaTypes.Schema, related, {
    operaUniqueId: getOperaUniqueIdValue(),
    utenteUniqueId: [
      ..._get(related.data, "bibliotecaQuery.utenti.list", []).map(
        (cat: any) => ({
          text: cat.nome + " " + cat.cognome + " (" + cat.email + ")",
          value: cat.uniqueId,
        })
      ),
    ],
  });

  return {
    graphql: {
      list,
      mutation,
      save: execSave,
      remove: execDelete,
      details: execDetails,
      listFilters: () => {
        return ` list(where: { resoIl:{_neq: null} })`;
      },
    },
    results: {
      save: resSave,
      remove: resDelete,
    },
    schema,
    dataDetails,
    related,
    extra: {
      slots: {
        header: ({ onCreate }) => <h2>Resi</h2>,
        ButtonBar: (args) => {
          return <></>;
        },
        edit: EditResi,
      },
      options: {
        closeWhenSaved: true,
      },
    },
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },

        {
          dataField: "utente.nome",
          text: "Nome utente",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "utente.cognome",
          text: "Cognome utente",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "opera.titolo",
          text: "Opere",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "opera.stato",
          text: "Stato",
          hidden: true,
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "resoIl",
          text: "Data Reso",
          // filter: selectFilter({
          //   ...filterDefault(),
          //   options: "uniqueId:resoIl",
          // }),
          // filterValue: (cell, row) => {
          //   return cell ? row.resoIl : "0";
          // },
          formatter: Formatters.dateFormatter,
          filter: textFilter(filterDefault()),
        },
        // {
        //   dataField: "",
        //   text: "",
        //   filter: selectFilter({
        //     placeholder: " ",
        //     options: () => [
        //       { value: "0", label: "Senza Reso" },
        //       { value: "1", label: "Con Reso" },
        //     ],
        //   }),
        //   filterValue: (cell, row) => {
        //     return "1";
        //   },
        //   // formatter: (cell: any) => {
        //   //   return cell ? <div style={{ textAlign: "center" }}>⭐</div> : null;
        //   // },
        // },
        // {
        //   dataField: "resoIl",
        //   text: "Data Reso",
        //   filter: selectFilter({
        //     ...filterDefault(),
        //     options: "uniqueId:resoIl",
        //   }),
        //   filterValue: (cell, row) => {
        //     return cell ? row.resoIl : "0";
        //   },
        //   formatter: Formatters.dateFormatter,
        // },
        // {
        //   dataField: "",
        //   formatter: (cell, row) => {
        //     return (
        //       <>
        //         {/* POPUP */}
        //         <Modal show={showodal} onHide={handleCloseModal}>
        //           <Modal.Header closeButton>
        //             {/* <Modal.Title>Modal heading</Modal.Title> */}
        //           </Modal.Header>
        //           <Modal.Body>Confermi il reso?</Modal.Body>
        //           <Modal.Footer>
        //             <Button variant="secondary" onClick={handleCloseModal}>
        //               Annulla
        //             </Button>
        //             <Button variant="primary">Conferma</Button>
        //           </Modal.Footer>
        //           <Modal.Footer></Modal.Footer>
        //         </Modal>

        //         {/* BOTTONE CHE FA COMPARIRE IL POPUP */}
        //         <Button style={{}} onClick={handleShowodal}>
        //           Esegui Reso
        //         </Button>
        //       </>
        //     );
        //   },
        // },
      ],
      dateNascoste: isMobile,
    },
  };
};
