import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const generiRoutes = [
  {
    path: "generi/List",
    element: <Generi />,
    // auth: authRoles.admin
  },
];

function Generi() {
  return <Crud module="generi" entity="generi" />;
}

export default generiRoutes;
