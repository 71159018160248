import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { FormViewer } from '@ai4/form-viewer';
import Ruoli from './Ruoli';
import { getEditFormViewer } from 'src/@bootstrap/components/Crud/data';
import $editSchema from './schema/user.edit.schema.json';
import $newSchema from './schema/user.new.schema.json';

export function UsersEdit(props: any) {
  const {
    children,
    record,
    dirty,
    show,
    extraData,
    onAddExtraData,
    handleClose,
    handleClosed,
  } = props;

    const formArgs = useMemo(() => {
        const { formSchema, ...args } = getEditFormViewer(props);
        return {
            ...args,
            schema: record.id ? $editSchema : $newSchema
        }
    }, [record, props]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            onExited={handleClosed}
            size='xl'
        >
            <Modal.Body>
                <Ruoli values={record} onChange={(values) => { onAddExtraData({ $ruoli: values }); }} />
                <FormViewer
                    {...formArgs}
                />
            </Modal.Body>
        </Modal>
    );
}

export default UsersEdit;
