import _get from "lodash/get";
import { SchemaTypes } from "@ai4/form-viewer";
import {
  filterDefault,
  pathToSlug,
} from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import { useSelectItemsPopulate } from "src/@bootstrap/components/Crud/data";
import { isMobile } from "react-device-detect";
import { textFilter } from "@ai4/react-bootstrap-table2-filter";

export const SCAFFALI = gql`
  query getScaffali($id: Guid!) {
    bibliotecaQuery {
      scaffali {
        list(where: { uniqueId: { _eq: $id } }) {
          uniqueId
          nome
          piano {
            uniqueId
            nome
          }
        }
      }
    }
  }
`;

export const RELATED = gql`
  query getPiani {
    bibliotecaQuery {
      piani {
        list {
          uniqueId
          nome
        }
      }
    }
  }
`;

export const SAVE = gql`
  mutation addScaffale($data: ScaffaleInput) {
    bibliotecaMutation {
      scaffale {
        salva(scaffale: $data) {
          uniqueId
          pianoUniqueId
          nome
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation deleteScaffale($uniqueId: String) {
    bibliotecaMutation {
      scaffale {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = () => {
  const list = "bibliotecaQuery.scaffali.list";
  const mutation = "bibliotecaMutation.scaffale";
  const { useLazyQuery, useQuery, useMutation } = useDataRequest();
  const related = useQuery(RELATED);
  // const { data, loading } = related;
  const query = useLazyQuery(SCAFFALI);
  const [execDetails, { data: dataDetails, loading }] = query;
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });

  var schema = require("./scaffali.schema.json");
  schema = useSelectItemsPopulate(schema as SchemaTypes.Schema, related, {
    pianoUniqueId: [
      ..._get(related.data, "bibliotecaQuery.piani.list", []).map(
        (cat: any) => ({
          // text: `${_get(cat, "piano.nome")}`,
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
    ],
  });

  return {
    graphql: {
      list,
      mutation,
      save: execSave,
      remove: execDelete,
      details: execDetails,
    },
    schema,
    results: {
      save: resSave,
      remove: resDelete,
    },
    dataDetails,
    related,
    extra: {
      options: {
        closeWhenSaved: true,
      },
    },
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "nome",
          text: "Nome",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "piano.nome",
          text: "Piano",
          filter: textFilter(filterDefault()),
        },
      ],
      dateNascoste: isMobile,
    },
  };
};
