export const navigations = [
  {
    name: "Dashboard",
    icon: "i-Bar-Chart",
    path: "dashboard/supervisor/DashboardSupervisor",
    type: "link",
  },
  {
    name: "Utenti",
    icon: "i-Business-Mens",
    path: "/utenti/List",
    type: "link",
  },
  {
    name: "Opere",
    type: "dropDown",
    icon: "i-Book",
    sub: [
      {
        name: "Opere",
        path: "/opere/List",
        type: "link",
      },
      {
        name: "Generi",
        path: "/generi/List",
        type: "link",
      },
      {
        name: "Autori",
        path: "/autori/List",
        type: "link",
      },
      {
        name: "Tipologie",
        path: "/tipologieOpere/List",
        type: "link",
      },
    ],
  },
  {
    name: "Prenotazioni e prestiti",
    type: "dropDown",
    icon: "i-Calendar-4",
    sub: [
      {
        name: "Prenotazioni",
        path: "/prenotazioni/List",
        type: "link",
      },
      {
        name: "Prestiti",
        path: "/prestiti/List",
        type: "link",
      },
      {
        name: "Resi",
        path: "/resi/List",
        type: "link",
      },
    ],
  },
  {
    name: "Impostazioni",
    type: "dropDown",
    icon: "i-Gear",
    sub: [
      {
        name: "Piani",
        path: "/piani/List",
        type: "link",
      },
      {
        name: "Scaffali",
        path: "/scaffali/List",
        type: "link",
      },
    ],
  },
  {
    name: "Account",
    icon: "i-Lock-2",
    path: "/users/List",
    type: "link",
    permissions: [
      ['ViewUsers']
    ]
  },
  // {
  //   name: "Account e permessi",
  //   type: "dropDown",
  //   icon: "i-Lock-2",
  //   sub: [
  //     {
  //       name: "Account",
  //       path: "/accounts/List",
  //       type: "link",
  //     },
  //     {
  //       name: "Ruoli",
  //       path: "/ruoli/List",
  //       type: "link",
  //     },
  //     {
  //       name: "Permessi",
  //       path: "/permessi/List",
  //       type: "link",
  //     },
  //   ],
  // },
  // {
  //   name: "Access control",
  //   type: "dropDown",
  //   icon: "nav-icon i-Administrator",
  //   sub: [
  //     {
  //       icon: "i-Administrator",
  //       name: "Demo (user list)",
  //       path: "/@demo/DemoList",
  //       type: "link",
  //     },
  //     {
  //       icon: "i-Administrator",
  //       name: "Users",
  //       path: "/users/List",
  //       type: "link",
  //     },
  //     {
  //       icon: "i-Administrator",
  //       name: "Tenants",
  //       path: "/tenants/List",
  //       type: "link",
  //     },
  //   ],
  // },
];
