import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DataContext } from '@ai4/data-request';
import Crud from 'src/@bootstrap/components/Crud/Crud';

interface PreProps {
    name: string;
    query_name?: string;
}

export function findList(lists, name) {
	if (!lists || !name) return null;
	const list = lists.find(l => l.nome === name);
	return list;
}

const PreGestioneListe = (props: PreProps) => {
	const { name, query_name } = props;
	const { domain, getClient } = useContext(DataContext);

	const onRefresh = useCallback(async () => {
		if (getClient) {
			if (query_name) {
				await getClient(domain).refetchQueries({
					include: [query_name],
				});
			}
			
		}
	}, [query_name]);

	useEffect(() => {
		return () => {
			onRefresh();
		}
	}, []);

	return <Crud module={name} entity={name} options={{ isLista: true}} />
}

export default PreGestioneListe;