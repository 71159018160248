import _get from "lodash/get";
import { useMemo, useState, useEffect } from "react";
import { getEditFormViewer } from "../common";
import Edit from "src/app/components/Edit";
import { FormViewer } from "@ai4/form-viewer";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import TitleBar from "src/app/components/TitleBar";
import $schema from "./prestiti.schema.json";
import { useSelectItemsPopulate } from "src/@bootstrap/components/Crud/data";
import Schema from "@ai4/form-viewer/dist/types/schema";

export function EditPrestiti(props: any) {
  const { record, onAddExtraData, handleClose } = props;

  const formArgs = useMemo(() => {
    const { initialValues, ...args } = getEditFormViewer(props);
    return {
      ...args,
      initialValues: {
        ...initialValues,
      },
    };
  }, [props]);

  //   const schema = useSelectItemsPopulate(
  //     $schema as unknown as any,
  //     related,
  //     {
  //         operatoreUniqueId: [
  //             ...related.data.anagrafiche.map((cat: any) => ({ text: `${cat.cognome} ${cat.nome} (${cat.mansione})`, value: cat.uniqueId })),
  //         ],
  //         tipologiaUniqueId: [
  //             ...related.data.tipologieEventiDiario.map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
  //         ],
  //     }
  // );

  // const [extraData, setExtraData] = useState({
  //   resoIl: _get(record, "resoIl", undefined) || undefined,
  // });

  // useEffect(() => {
  //   onAddExtraData({
  //     ...extraData,
  //   });
  // }, [extraData]);

  // const [showDetailRow, setShowDetailRow] = useState(false);
  // const handleCloseDetailRow = () => setShowDetailRow(false);
  // const handleShowDetailRow = () => setShowDetailRow(true);

  // const [prenotatoAl, setPrenotatoAlDate] = useState(false);

  // const handleSaveExtraData = () => {
  //   console.log("handleSaveExtraData");
  //   const resoIl = new Date();
  //   setExtraData({ ...extraData, resoIl });

  // handleCloseModal();
  // };

  // const operaState = (prestiti, selectedOpera) => {
  //   //se non c'è un uniqueid e se c'è selezionata un'opera
  //   console.log("prestiti", prestiti);
  //   console.log("selectedOpera", selectedOpera);

  //   const prestitiWithSelectedOpera = prestiti.filter(
  //     (element) => element.operaUniqueId === selectedOpera
  //   );
  //   console.log("prestitoFound", prestitiWithSelectedOpera);
  //   let operaDisponibile = true;
  //   if (prestitiWithSelectedOpera) {
  //     operaDisponibile =
  //       prestitiWithSelectedOpera[0].opera.stato === "DISPONIBILE";
  //   }

  //   console.log("opera disponibile? ", operaDisponibile);
  //   if (!operaDisponibile) {
  //     let higherDatePrestito;
  //     if (prestitiWithSelectedOpera.length > 1) {
  //       const prestatoAlArray = prestitiWithSelectedOpera.map(
  //         (v) => new Date(v.prestatoAl)
  //       );
  //       higherDatePrestito = new Date(Math.max.apply(null, prestatoAlArray));
  //       console.log("prestatoAlArray", higherDatePrestito);
  //     } else {
  //       //TODO: da testare
  //       higherDatePrestito = new Date(prestitiWithSelectedOpera[0].prestatoAl);
  //     }

  //     setPrenotatoAlDate(higherDatePrestito);
  //   }

  //   !operaDisponibile ? handleShowDetailRow() : handleCloseDetailRow();
  // };

  const [newRecord, setNewRecord] = useState(false);

  return (
    <Edit>
      <TitleBar
        path={[`${record.uniqueId ? record.opera.titolo : "Nuovo Prestito"}`]}
        onBack={handleClose}
      />
      <FormViewer
        {...formArgs}
        schema={$schema as Schema}
        onInit={(form) => {
          setNewRecord(
            Object.keys(record).length === 0 && record.constructor === Object
          );
        }}
        onChange={(form) => {
          console.log("onChange", form);
        }}
        onSubmit={(form) => {
          console.log("onSubmit", form);
        }}
        // onChange={(form) => {
        //   //controllare anche che nel caso si resetti il campo
        //   if (
        //     !record.uniqueId &&
        //     form.changedValues.hasOwnProperty("operaUniqueId")
        //   ) {
        //     operaState(formArgs.rows, form.changedValues.operaUniqueId);
        //   }
        // }}
      />
      {/* {showDetailRow ? (
        <>
          <Container>
            <Row>
              <Col>{"prenotato fino al: " + prenotatoAl}</Col>
            </Row>
          </Container>
        </>
      ) : (
        ""
      )} */}
      {/* POPUP */}
      {/* <Modal show={showodal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title> }
        </Modal.Header>
        <Modal.Body>Confermi il reso?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annulla
          </Button>
          <Button variant="primary" onClick={handleSaveExtraData}>
            Conferma
          </Button>
        </Modal.Footer>
        <Modal.Footer></Modal.Footer>
      </Modal> */}

      {/* BOTTONE CHE FA COMPARIRE IL POPUP */}
      {/* <Button style={{}} onClick={handleShowodal}>
        Esegui Reso
      </Button> */}
    </Edit>
  );
}

export default EditPrestiti;
