import _get from "lodash/get";
import { isMobile } from "react-device-detect";
import { SchemaTypes } from "@ai4/form-viewer";
import { textFilter } from "@ai4/react-bootstrap-table2-filter";
import $schema from "./tipologieOpere.schema.json";
import { filterDefault, pathToSlug } from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import { ModuleListOptions } from "../generi/generi";

let schema = $schema as SchemaTypes.Schema;

export const SAVE = gql`
  mutation addTipologieOpere($data: TipologiaOperaInput) {
    bibliotecaMutation {
      tipologiaOpera {
        salva(tipologiaOpera: $data) {
          uniqueId
          nome
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation deleteTipologieOpere($uniqueId: String) {
    bibliotecaMutation {
      tipologiaOpera {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = (options: ModuleListOptions) => {
  const list = "bibliotecaQuery.tipologieOpere.list";
  const mutation = "bibliotecaMutation.tipologiaOpera";
  const { useLazyQuery, useQuery, useMutation } = useDataRequest();
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });

  return {
    graphql: {
      list,
      mutation,
      save: execSave,
      remove: execDelete,
    },
    schema,
    results: {
      save: resSave,
      remove: resDelete,
    },
    extra: {
      options: {
        closeWhenSaved: true,
      },
    },
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "nome",
          text: "Nome",
          filter: textFilter(filterDefault()),
        },
      ],
      dateNascoste: isMobile || options.isLista,
    },
  };
};
