import React, { Component, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";
// import { Helpers } from '@ai4/records-management';
import TableWidget from "./TableWidget";
import EmptyBoardWidget from "./EmptyBoardWidget";

interface Props {
  data?: {
    uniqueId: string;
    cartellaUniqueId: string;
    assistito: string;
    dataPianificata: string;
    operatore: string;
    stato: number;
    tipologia: number;
  }[];
}

export function UltimeOpereInserite(props: Props) {
  const { data = [] } = props;
  const state = {
    dataTablePage: 0,
    dataTablePerPage: 5,
  };

  let { dataTablePage, dataTablePerPage } = state;

  let dotStyle = { marginLeft: "4px", backgroundColor: "black !important" };

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="ul-widget__head border-0 mb-2">
          <div className="ul-widget__head-label">
            <h3 className="ul-widget__head-title">Ultime opere inserite</h3>
          </div>
        </div>
        {data.length > 0 ? (
          <TableWidget
            data={data}
            ipp={dataTablePerPage}
            head={() => (
              <thead>
                <tr className="ul-widget6__tr--sticky-th">
                  <th scope="col">Opera</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Piano</th>
                  <th scope="col">Scaffale</th>
                  <th scope="col">Azione</th>
                </tr>
              </thead>
            )}
            row={(item, i) => (
              <tr key={i}>
                <td>{item.opera}</td>
                <td>{item.tipo}</td>
                <td>{item.piano}</td>
                <td>{item.scaffale}</td>
                <td>
                  <a href={`/opere/dettaglio/${item.uniqueId}`} target="_blank">
                    <i className="bi bi-arrow-right-circle" />
                  </a>
                </td>
              </tr>
            )}
          />
        ) : (
          <EmptyBoardWidget text="Nessuna opera inserita recentemente"></EmptyBoardWidget>
        )}
      </div>
    </div>
  );
}

export default UltimeOpereInserite;
