import _get from "lodash/get";
import { SchemaTypes } from "@ai4/form-viewer";
import {
  filterDefault,
  pathToSlug,
} from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import {
  useFormBuilderSchemaLoader,
} from "src/@bootstrap/components/Crud/data";
import EditOpere from "./EditOpere";
import { isMobile } from "react-device-detect";
import { textFilter } from "@ai4/react-bootstrap-table2-filter";
import { useSelectItemsPopulate } from "src/app/helpers";

export const OPERE = gql`
  query getOpere($id: Guid!) {
    bibliotecaQuery {
      opere {
        list(where: { uniqueId: { _eq: $id } }) {
          uniqueId
          titolo
          genere {
            uniqueId
            nome
          }
          autore {
            uniqueId
            nome
          }
          stato
          piano {
            uniqueId
            nome
          }
          scaffale {
            uniqueId
            pianoUniqueId
            nome
          }
          tipologia {
            uniqueId
            nome
          }
          tipologiaOperaUniqueId
          edizione
          pagine
          pubblicazione
          lingua
          periodo
          prezzo
          etichetta
          note
          prenotazioni {
            prenotatoDal
            prenotatoAl
            insertDate
          }
          prestiti {
            prestatoDal
            prestatoAl
            insertDate
          }
        }
      }
    }
  }
`;

export const RELATED = gql`
  query getRelatedOpere {
    bibliotecaQuery {
      generi {
        list {
          uniqueId
          nome
        }
      }
      autori {
        list {
          uniqueId
          nome
        }
      }
      piani {
        list {
          uniqueId
          nome
        }
      }
      scaffali {
        list {
          uniqueId
          pianoUniqueId
          nome
        }
      }
      tipologieOpere {
        list {
          uniqueId
          nome
        }
      }
    }
  }
`;

export const SAVE = gql`
  mutation addOpera($data: OperaInput) {
    bibliotecaMutation {
      opera {
        salva(opera: $data) {
          uniqueId
          genereUniqueId
          autoreUniqueId
          tipologiaOperaUniqueId
          pianoUniqueId
          scaffaleUniqueId
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation deleteOpera($uniqueId: String) {
    bibliotecaMutation {
      opera {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = () => {
  const list = "bibliotecaQuery.opere.list";
  const mutation = "bibliotecaMutation.opera";
  const { useLazyQuery, useQuery, useMutation } = useDataRequest();
  const related = useQuery(RELATED);
  // const { data, loading } = related;
  const query = useLazyQuery(OPERE);
  const [execDetails, { data: dataDetails, loading }] = query;
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });

  var schema = require("./opere.schema.json");
  schema = useSelectItemsPopulate(schema as SchemaTypes.Schema, related, {
    genereUniqueId: [
      ..._get(related.data, "bibliotecaQuery.generi.list", []).map(
        (cat: any) => ({
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
      { listManagement: { name: 'generi', query_name: 'getRelatedOpere' } },
    ],
    tipologiaOperaUniqueId: [
      ..._get(related.data, "bibliotecaQuery.tipologieOpere.list", []).map(
        (cat: any) => ({
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
      { listManagement: { name: 'tipologieOpere', query_name: 'getRelatedOpere' } },
    ],
    autoreUniqueId: [
      ..._get(related.data, "bibliotecaQuery.autori.list", []).map(
        (cat: any) => ({
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
      { listManagement: { name: 'autori', query_name: 'getRelatedOpere' } },
    ],
    pianoUniqueId: [
      ..._get(related.data, "bibliotecaQuery.piani.list", []).map(
        (cat: any) => ({
          // text: `${_get(cat, "piano.nome")}`,
          text: cat.nome,
          value: cat.uniqueId,
        })
      ),
    ],
  });

  const onChange = (args: any) => {
		const {
			values,
			schema,
			getSchemaNode,
			setSchemaNode,
		} = args;
    
		const pianoUniqueId = _get(values, `pianoUniqueId`);
    const items = [..._get(related.data, "bibliotecaQuery.scaffali.list", [])
    .filter(c => c.pianoUniqueId === pianoUniqueId)
    .map(
      (cat: any) => ({
        text: cat.nome,
        value: cat.uniqueId,
      })
    )];
    const name = `scaffaleUniqueId`;
    const node = getSchemaNode(name);
    if (node) {
      setSchemaNode(name, {
        ...node,
        props: {
          ...(node.props || {}),
          items,
        },
        hidden: !pianoUniqueId
      });
    }
	};

  return {
    graphql: {
      list,
      mutation,
      save: execSave,
      remove: execDelete,
      details: execDetails,
    },
    results: {
      save: resSave,
      remove: resDelete,
    },
    schema,
    dataDetails,
    extra: {
      slots: {
        edit: EditOpere,
      },
      options: {
        closeWhenSaved: true,
      },
    },
		methods: {
		  onFormInit: onChange,
		  onFormChange: onChange,
		},
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "titolo",
          text: "Titolo",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "etichetta",
          text: "Etichetta",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "autore.nome",
          text: "Autore",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "tipologia.nome",
          text: "Tipo",
          filter: textFilter(filterDefault()),
          hidden: isMobile,
        },
        {
          dataField: "piano.nome",
          text: "Piano",
          filter: textFilter(filterDefault()),
        },
        {
          dataField: "scaffale.nome",
          text: "Scaffale",
          filter: textFilter(filterDefault()),
        },
      ],
      dateNascoste: isMobile,
    },
  };
};
