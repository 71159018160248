import React, { useState, useEffect, useContext, Component } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { navigations } from "src/template/ai4/navigations";

import { navigations as navigationsOriginal } from "src/app/template/navigations";

import { merge } from "lodash";
import { selectPermissions } from "src/@bootstrap/features/auth";
import { classList } from "src/@bootstrap/helpers";
import { isMobile } from "react-device-detect";
import ScrollBar from "react-perfect-scrollbar";
import { ConfigContext } from "src/@bootstrap/Config";
import { DropDownMenu } from "src/@bootstrap/template/@gull";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDataRequest } from "@ai4/data-request";
import {
    setLayoutSettings,
    setDefaultSettings,
} from "src/@bootstrap/features/layout";
import { logoutUser } from "src/@bootstrap/features/user";
import { withRouter } from "src/@bootstrap/services/router";

export function NavigationLoader(props) {
    const { hasFeature } = useContext(ConfigContext);
    let location = useLocation();
    const permissions = useSelector(selectPermissions);
    const [navigationsOriginalReady, setNavigationsOriginalReady] = useState();
    const [schema, setSchema] = useState();
    const [selected, setSelected] = useState();
    const { useRestRequest } = useDataRequest();
    const [reqLoad, resLoad] = useRestRequest({
        path: "api/{ver}/navigation",
        method: "GET",
        jwt: true,
    });

    useEffect(() => {
        if (navigationsOriginalReady) return;
        // replace sub with children
        var $navigationsOriginalReady = [...navigationsOriginal];
        $navigationsOriginalReady.forEach(function (data) {
            data["children"] = data["sub"];
            delete data["sub"];
        });
        setNavigationsOriginalReady($navigationsOriginalReady);

        if (hasFeature("navigation_local_storage")) {
            if (!localStorage.getItem("navigations")) {
                console.log(
                    "localstorage is empty populate from navigations.js and replace sub with children"
                );
                // if localstorage is empty populate from navigations.js
                localStorage.setItem(
                    "navigations",
                    JSON.stringify(navigationsOriginalReady, null, 2)
                );
            }

            // const navigations = JSON.parse(localStorage.getItem('navigations'));
        }
    }, []);

    useEffect(() => {
        if (!navigationsOriginalReady) return;
        (async () => {
            if (hasFeature("navigation_async_storage")) {
                try {
                    const schema = await reqLoad();
                    setSchema(schema);
                } catch (e) {
                    setSchema(navigationsOriginalReady);
                }
            } else {
                setSchema(navigationsOriginalReady);
            }
        })();
    }, [navigationsOriginalReady]);

    useEffect(() => {
        if (!schema) return;
        // find selected item by route
        const selected = schema.find((item) => {
            if (item.path && item.path === location.pathname) {
                return true;
            }
            if (item.children) {
                return item.children.find((c) => {
                return c.path === location.pathname;
                });
            }
        });
        setSelected(selected);
    }, [schema, location.pathname]);

    return (
        <Layout1Sidenav
        {...props}
        navigations={schema}
        permissions={permissions}
        location={location}
        selected={selected}
        />
    );
}

export function checkMenuItemPermission(item, userPermissions) {
    const { permissions } = item;
    if (permissions && permissions.length > 0) {
        // AND: every group must be "true"
        return permissions.reduce((acc, group) => {
            if (group.length === 0) return acc;
            // OR: at least one must be present
            if (
                !group.find((p) =>
                    userPermissions.map((p) => p.replace("Permission.", "")).includes(p)
                )
            ) {
                return false;
            }
            return acc;
        }, true);
    }
    return true;
}

class Layout1Sidenav extends Component {
    windowListener = null;

    state = {
        selectedItem: null,
        navOpen: true,
        secondaryNavOpen: false,

        userRole: JSON.parse(localStorage.getItem("carts")),
    };

    onMainItemMouseEnter = (item) => {
        if (item.type === "dropDown") {
            this.setSelected(item);
            this.openSecSidenav();
        } else {
            this.setSelected(item);
            this.closeSecSidenav();
        }
    };

    onMainItemMouseLeave = () => {
        // this.closeSecSidenav();
    };

    setSelected = (selectedItem) => {
        this.setState({ selectedItem });
        // console.log(selectedItem);
    };

    removeSelected = () => {
        this.setState({ selectedItem: null });
        // console.log('removed');
    };

    openSecSidenav = () => {
        let { setLayoutSettings, settings } = this.props;

        setLayoutSettings(
            merge({}, settings, {
                layout1Settings: {
                leftSidebar: {
                    secondaryNavOpen: true,
                },
                },
            })
        );
    };

    closeSecSidenav = () => {
        console.log("closing sec sidenav");
        let { setLayoutSettings, settings } = this.props;
        let other = {};

        if (isMobile) {
            other.open = false;
        }

        setLayoutSettings(
            merge({}, settings, {
                layout1Settings: {
                leftSidebar: {
                    ...other,
                    secondaryNavOpen: false,
                },
                },
            })
        );
    };

    closeSidenav = () => {
        console.log("closing sidenav");

        let { setLayoutSettings, settings } = this.props;
        setLayoutSettings(
            merge({}, settings, {
                layout1Settings: {
                leftSidebar: {
                    open: false,
                    secondaryNavOpen: false,
                },
                },
            })
        );
    };

    openSidenav = () => {
        console.log("opening sidenav");
        let { setLayoutSettings, settings } = this.props;
        setLayoutSettings(
            merge({}, settings, {
                layout1Settings: {
                leftSidebar: {
                    open: true,
                },
                },
            })
        );
    };

    componentDidMount() {
        if (this.state.selectedItem === null) this.closeSecSidenav();

        if (window)
        if (window.innerWidth < 1200) {
            this.closeSidenav();
        } else {
            this.openSidenav();
        }

        this.windowListener = window.addEventListener("resize", ({ target }) => {
            if (window.innerWidth < 1200) {
                this.closeSidenav();
            } else {
                this.openSidenav();
            }
        });
    }

    componentWillUnmount() {
        if (this.windowListener) {
            window.removeEventListener("resize", this.windowListener);
        }
    }

    render() {
        let {
            navigations,
            settings,
            permissions: userPermissions,
            selected,
        } = this.props;
        if (!navigations) return null;

        return (
        <div className="side-content-wrap">
            <ScrollBar
                className={classList({
                    "sidebar-left o-hidden rtl-ps-none": true,
                    open: settings.layout1Settings.leftSidebar.open,
                })}
                // id="mainsidenav"
            >
            <ul className="navigation-left">
                {navigations.map((item, i) => {
                    if (!checkMenuItemPermission(item, userPermissions)) return null;

                    if (item.path && item.path.includes("http")) {
                        item.type = "extLink";
                        item.target = "_blank";
                    }

                    return (
                        <li
                        className={classList({
                            "nav-item": true,
                            active: selected === item,
                            hover: this.state.selectedItem === item,
                        })}
                        onMouseEnter={() => {
                            this.onMainItemMouseEnter(item);
                        }}
                        onMouseLeave={this.onMainItemMouseLeave}
                        key={i}
                        >
                        {item.path && item.type !== "extLink" && (
                            <NavLink className="nav-item-hold" to={item.path}>
                            <i className={`nav-icon ${item.icon}`}></i>
                            <span className="nav-text">{item.name}</span>
                            </NavLink>
                        )}
                        {item.path && item.type === "extLink" && (
                            <a
                            className="nav-item-hold"
                            href={item.path}
                            target={item.target || "_self"}
                            >
                            <i className={`nav-icon ${item.icon}`}></i>
                            <span className="nav-text">{item.name}</span>
                            </a>
                        )}
                        {!item.path && (
                            <div className="nav-item-hold">
                            <i className={`nav-icon ${item.icon}`}></i>
                            <span className="nav-text">{item.name}</span>
                            </div>
                        )}
                        <div className="triangle"></div>
                        </li>
                    );
                })}
            </ul>
            </ScrollBar>

            <ScrollBar
                className={classList({
                    "sidebar-left-secondary o-hidden rtl-ps-none": true,
                    open: settings.layout1Settings.leftSidebar.secondaryNavOpen,
                })}
            >
            {this.state.selectedItem && this.state.selectedItem.children && (
                <DropDownMenu
                    menu={this.state.selectedItem.children}
                    closeSecSidenav={this.closeSecSidenav}
                    userPermissions={userPermissions}
                    location={this.props.location}
                ></DropDownMenu>
            )}
            <span></span>
            </ScrollBar>
            <div
                onMouseEnter={this.closeSecSidenav}
                className={classList({
                    "sidebar-overlay": true,
                    open: settings.layout1Settings.leftSidebar.secondaryNavOpen,
                })}
            ></div>
        </div>
        );
    }
}

Layout1Sidenav.propTypes = {
    setLayoutSettings: PropTypes.func.isRequired,
    setDefaultSettings: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    setDefaultSettings: PropTypes.func.isRequired,
    setLayoutSettings: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    user: state.user,
    settings: state.layout.settings,
});

export default withRouter(
    connect(mapStateToProps, {
        setLayoutSettings,
        setDefaultSettings,
        logoutUser,
    })(NavigationLoader)
);
