import React from "react";
import { Route, Theme } from "src/@bootstrap/types";

interface AppContextValues {
    routes: Route[];
    theme?: Theme;
}

export const AppContext = React.createContext<AppContextValues>({
    routes: [],
});

export const AppProvider = AppContext.Provider;
