import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const pianiRoutes = [
  {
    path: "piani/List",
    element: <Piani />,
    // auth: authRoles.admin
  },
];

function Piani() {
  return <Crud module="piani" entity="piani" />;
}

export default pianiRoutes;
