import { lazy } from "react";
import { authRoles } from "src/app/roles";
import Crud from "../../../@bootstrap/components/Crud/Crud";

const usersRoutes = [
    {
        path: "users/List",
        element: <Users />,
        auth: authRoles.admin
    },
];

function Users() {
    return <Crud module='@users' entity='users' />;
};

export default usersRoutes;
