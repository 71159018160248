import _get from 'lodash/get';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'src/@bootstrap/services/store';
import { User } from 'src/@bootstrap/types';

interface AuthState {
    authenticated: boolean;
    token?: string;
    user?: User;
    logged_at?: number; // unix timestamp in seconds
}

const initialState: AuthState = {
    authenticated: false,
};

export const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loggedIn: (state, action: PayloadAction<User>) => {
            state.authenticated = true;
            state.logged_at = Math.floor(Date.now() / 1000);
            state.user = action.payload;
        },
        loggedOut: state => {
            state.authenticated = false;
            state.user = {};
        },
    },
});

export const { loggedIn, loggedOut } = authReducer.actions;

export const selectAuthenticated = (state: RootState) => state.auth.authenticated;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectPermissions = (state: RootState) => _get(state, 'auth.user.permissions', []);

/***
 * helper to check permissions
 */
export function hasPermission(perm: string | string[], permissions: string[]) {
    if (!Array.isArray(perm)) perm = [perm];
    return !!permissions.map(p => p.replace('Permission.', '')).find(p => perm.includes(p));
}

export default authReducer.reducer