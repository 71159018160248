import _set from "lodash/set";
import _get from "lodash/get";
import React, {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Accordion, Button, Spinner, Stack } from "react-bootstrap";
import {
    dateFilter,
    selectFilter,
    customFilter,
} from "@ai4/react-bootstrap-table2-filter";
import RecordsManagement, { Formatters } from "@ai4/records-management";
import { FormViewer } from "@ai4/form-viewer";
import { gql, useDataRequest, useMutationTransformer } from "@ai4/data-request";
import { ConfigContext } from "../../Config";
import { changeUrlParams, filterDefault, pathToSlug } from "./helpers";
import {
    useDataDecorator,
    useFormBuilderSchemaLoader,
    useTreeDecorator,
} from "./data";

export function addDateColumns(columns: any, prefix?: string) {
    return [
        ...columns,
        {
            dataField: `${prefix ? prefix+'.' : ''}insertDate`,
            text: "Inserito il",
            formatter: Formatters.dateFormatter,
        },
        {
            dataField: `${prefix ? prefix+'.' : ''}updateDate`,
            text: "Aggiornato il",
            formatter: Formatters.dateFormatter,
        },
    ];
}

function addInEvidenzaColumn(columns: any) {
    const selectOptions = [
        { value: "0", label: "Non attivo" },
        { value: "1", label: "Attivo" },
    ];
    return [
        {
            dataField: "inEvidenza",
            text: "",
            size: 10,
            headerStyle: { width: "50px" },
            filter: selectFilter({
                placeholder: " ",
                options: () => selectOptions,
            }),
            filterValue: (cell, row) => {
                return cell ? "1" : "0";
            },
            formatter: (cell: any) => {
                return cell ? <div style={{ textAlign: "center" }}>⭐</div> : null;
            },
        },
        ...columns,
    ];
}

interface Props {
    title?: string;
    module?: string;
    entity?: string;
    options?: any;
    // minicrud
    path?: string;
    slots?: (args: any) => any;
}

const PreCrud = (props: Props) => {
    const { module, entity, path } = props;
    const {
        defaultEntity,
        components = {},
    } = require(`../../../app/views/${module}/@module`);
    const currentEntity = entity || defaultEntity;
    const { usePreModule = () => ({}) } = path
        ? require(`../../../app/views/${path}`)
        : require(`../../../app/views/${module}/${currentEntity}`);
    const response = usePreModule();
    if (!response) return null;

    if (components[currentEntity]) {
        const Component = components[currentEntity];
        return <Component {...props} />;
    }
    return <Crud {...props} options={{ ...props.options, ...response }} />;
};

const googleMapsApiKey = "";
const fileMaxSize = "";

const Crud = (props: Props) => {
    const { title, module, entity, options, path, slots } = props;
    const {
        defaultEntity,
        entities,
        labels,
    } = require(`../../../app/views/${module}/@module`);
    const currentEntity = entity || defaultEntity;
    const { useModule } = path
        ? require(`../../../app/views/${path}`)
        : require(`../../../app/views/${module}/${currentEntity}`);
    const {
        graphql,
        dataList,
        dataDetails,
        schema,
        actions,
        results,
        features,
        extra = {},
        filters,
        table,
        methods = {},
        titleGenerator,
        additionalComponent,
    } = useModule(options);
    const { getEnv } = useContext(ConfigContext);
    const metaDataPrefix = table.metaDataPrefix || getEnv('REACT_APP_GQL_METADATA_PREFIX');

    // const defaultSchema = useFormBuilderSchemaLoader(graphql.mutation);
    // const formSchema = schema === undefined ? defaultSchema : schema;
    const formSchema = schema;
    const transformData = useMutationTransformer(graphql.mutation);
    const defaultSave = useCallback(
        async (values) => {
            const { extraData = {} } = values;
            if (!transformData || !graphql.save) return;
            const res = await graphql.save({
                variables: {
                    data: {
                        ...transformData(values),
                    },
                    ...extraData,
                },
            });
            const data = _get(res, `data.${graphql.mutation}.salva`);
            return {
                ...data,
                ...values,
            };
        },
        [transformData]
    );

    const defaultRemove = useCallback(async (values) => {
        if (!graphql.remove) return;
        const { uniqueId, name } = values;
        await graphql.remove({
            variables: {
                uniqueId: uniqueId,
            },
        });
        return values;
    }, []);

    const getListQuery = useCallback(() => {
        const [p1, p2] = graphql.list.split(".");
        const raw = `
			query ${graphql.queryWithVars
                ? graphql.queryWithVars()
                : pathToSlug(graphql.list)
            } {
				${p1} {
					${p2} {
						${graphql.listFilters ? graphql.listFilters() : "list"} {
							uniqueId
                            ${metaDataPrefix ? `${metaDataPrefix} {` : ''}
								insertDate
								updateDate
                            ${metaDataPrefix ? `}` : ''}
							${table.inEvidenza ? `inEvidenza` : ""}
							${table.columns
                            .map((col: any) => {
                                if (col.noData) return "";
                                let field = col.dataField;
                                if (field.includes(".")) {
                                    // from obj.subobj to obj { subobj }
                                    const [obj, subobj] = field.split(".");
                                    field = `${obj} {
                                        # uniqueId
                                        ${subobj}
                                    }`;
                                }
                                return `${col.dataNode || field}`;
                            })
                            .join("\n")}
						}
					}
				}
			}
		`;
        return gql`
      ${raw}
    `;
    }, []);

    const {
        save = defaultSave,
        remove = defaultRemove,
        upload,
        form,
    } = actions || {};
    const { creation = true } = features || {};

    const [currentFilters, setCurrentFilters] = useState<any>();

    // automatically retrieve schema for table rendering
    const { useQuery } = useDataRequest();
    const query = useQuery(getListQuery(), {
        skip: !!dataList,
        variables: {
            ...currentFilters,
        },
    });
    const { data, loading, refetch } = query;
    const dataListLoaded = useDataDecorator(graphql.list, data);
    let rows = dataList || dataListLoaded;
    if (actions && actions.list) {
        if (actions.list.onLoadList && rows) {
            rows = actions.list.onLoadList(rows);
        }
        /*if (actions.list.treeRender && rows) {
                rows = useTreeDecorator(rows, actions.list.treeRender);
            }*/
    }

    // parse single row details
    const [edit, setEdit] = useState(false);
    const details = useDataDecorator(graphql.list, dataDetails);
    const record = useMemo(() => {
        if (!edit) return undefined;
        let item = details ? details[0] : undefined;
        if (actions && actions.details) {
            if (actions.details.onLoadDetails) {
                item = actions.details.onLoadDetails(item);
            }
            if (actions.details.onParseDataDetails) {
                item = actions.details.onParseDataDetails(dataDetails);
            }
        }
        return item;
    }, [edit, details, dataDetails]);

    // records-management props
    let onSelectedRow: any;
    if (actions && actions.list && actions.list.onSelectedRow) {
        onSelectedRow = async (row, args) => {
            setEdit(true);
            await actions.list.onSelectedRow(row, args);
        };
    } else if (graphql.details) {
        onSelectedRow = async (row, args) => {
            setEdit(true);
            await graphql.details({ variables: { id: row.uniqueId } });
        };
    }

    let columns = table.columns;
    if (!table.dateNascoste) {
        columns = addDateColumns(columns, metaDataPrefix);
    }
    if (table.inEvidenza) {
        columns = addInEvidenzaColumn(columns);
    }
    // attach full data to filter (uff)
    columns = columns.map((column) => {
        if (column.filter) {
            return _set(column, "filter.props.allData", rows);
        }
        return column;
    });

    const args = {
        module,
        entity,
        ...upload,
        ...form,
        ...extra,
        columns,
        rows: rows ? rows.map((i) => ({ ...i, id: i.id || i.uniqueId })) : rows, // for bootstrap-table uniqueness when "clickRowToSelect"
        record,
        dataLoading: loading || graphql.loading,
        formSchema,
        formOptions: {
            googleMapsApiKey,
            fileMaxSize,
            typesOfFile: {
                text: [
                    "txt",
                    "csv",
                    "doc",
                    "docx",
                    "xls",
                    "xlsx",
                    "rtf",
                    "pdf",
                    "odt",
                    "ppt",
                    "pptx",
                    "p7m",
                ],
                image: ["gif", "jpg", "jpeg", "png", "bmp", "tiff", "psd", "pdf"],
                certificate: [
                    "crt",
                    "cer",
                    "ca-bundle",
                    "p7b",
                    "p7c",
                    "p7s",
                    "pem",
                    "p12",
                    "pfx",
                    "pem",
                ],
                archive: ["zip", "xml", "json", "7z", "rar"],
                video: [
                    "mpeg",
                    "mpg",
                    "avi",
                    "webm",
                    "mov",
                    "mp4",
                    "wmv",
                    "mkv",
                    "flv",
                ],
            },
            textMaxLength: 255,
        },
        options: {
            forceModal: false,
            hideErrors: true,
            closeWhenSaved: _get(extra, "options.closeWhenSaved", false),
            ...(extra && extra.options ? extra.options : {}),
        },
        submitResponse: results.save,
        tableClassName: "wp-list-table widefat fixed striped table-view-list posts",
        slots: {
            header: ({ onCreate }) => {
                const creationFn =
                    extra && extra.options && extra.options.onCreate
                        ? extra.options.onCreate
                        : onCreate;
                return (
                    <Fragment>
                        {entities && entities.length > 0 && (
                            <Stack direction="horizontal" gap={3} className="mb-3">
                                <h2>
                                    {titleGenerator
                                        ? titleGenerator(props)
                                        : title || labels[currentEntity]}
                                </h2>
                                {creation && (
                                    <div>
                                        <Button
                                            variant="success"
                                            onClick={() =>
                                                creationFn(
                                                    extra.options && extra.options.onCreateInitialValues
                                                        ? { ...extra.options.onCreateInitialValues }
                                                        : {}
                                                )
                                            }
                                        >
                                            Aggiungi
                                        </Button>
                                    </div>
                                )}
                            </Stack>
                        )}
                        {filters && (
                            <>
                                <Accordion className="mb-3">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Ricerca</Accordion.Header>
                                        <Accordion.Body>
                                            <FormViewer
                                                schema={filters.schema as any}
                                                initialValues={filters.initialValues || {}}
                                                onSubmit={(args) => {
                                                    const { values, form } = args;
                                                    const filters = { ...values, t: Date.now() };
                                                    setCurrentFilters(filters);
                                                }}
                                                slots={{
                                                    ButtonBar: (args) => (
                                                        <>
                                                            <div className="d-flex justify-content-end flex-row bd-highlight gap-3">
                                                                <Button
                                                                    variant="secondary"
                                                                    onClick={() => {
                                                                        args.form.resetForm({});
                                                                        args.form.submitForm();
                                                                    }}
                                                                >
                                                                    Annulla filtro
                                                                </Button>
                                                                <Button variant="primary" type="submit">
                                                                    Applica
                                                                </Button>
                                                            </div>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </>
                        )}
                    </Fragment>
                );
            },
            ...(slots ? slots({ extra }) : {}),
            ...(extra && extra.slots ? extra.slots : {}),
        },
        onBoot: (args: any) => { },
        onInit: (args: any) => {
            if (methods.onFormInit) {
                methods.onFormInit(args);
            }
        },
        onChange: (args: any) => {
            if (methods.onFormChange) {
                methods.onFormChange(args);
            }
        },
        onSubmit: (args: any) => {
            const { values, form } = args;
            return new Promise((resolve, reject) => {
                let newValues = {
                    ...values,
                };
                if (record && record.uniqueId) {
                    newValues.uniqueId = record.uniqueId;
                }
                save(newValues)
                    .then((res) => {
                        resolve({
                            data: res,
                        });
                        // form.resetForm({ values });
                        // setEdit(false);
                    })
                    .catch((err) => {
                        reject(err);
                    })
                    .finally(() => {
                        form.setSubmitting(false);
                    });
            });
        },
        onCancel: () => {
            setEdit(false);
        },
        onSelectedRow,
        onDeleteRow: (row) => {
            return remove(row);
        },
        tableProps: {
            filtersClasses: "filters-row",
        },
        paginationProps: {
            paginationSize: 1,
        },
    };

    if (!formSchema) return <Spinner animation="border" />; // <>Schema del form non caricato.</>;
    if (!module) return <>Modulo non trovato.</>;

    return (
        <div className="wrap">
            {additionalComponent && additionalComponent()}
            <RecordsManagement {...args} />
        </div>
    );
};

export default PreCrud;
