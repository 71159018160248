import React from "react";
import { useSelector } from 'react-redux';
import { hasPermission, selectPermissions } from 'src/@bootstrap/features/auth';
import { authRoles } from "src/app/roles";
import Permissions from '@ai4/ui-permissions';
import { Spinner } from "react-bootstrap";

const configRoutes = [
  {
    path: "config/roles/List",
    element: <PermissionsWrapper type='roles' />,
    auth: authRoles.admin
  },
  {
    path: "config/permissions/main",
    element: <PermissionsWrapper type='permissions' />,
    auth: authRoles.admin
  }
];

function PermissionsWrapper(props: { type: 'roles' | 'permissions' }) {
  const permissions = useSelector(selectPermissions);
  
  const args = {
    canRoles: {
      create: hasPermission('CreateRoles', permissions),
      edit: hasPermission('UpdateRoles', permissions),
      delete: hasPermission('DeleteRoles', permissions),
    },
    canPermissions: {
      addToRole: hasPermission('UpdateRoleClaims', permissions),
      deleteFromRole: hasPermission('UpdateRoleClaims', permissions),
    },
  }

  if (!permissions || permissions.length === 0) return <Spinner />
  return <Permissions {...props} {...args} />
}

export default configRoutes;
