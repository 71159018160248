const layout1Settings = {
  leftSidebar: {
    open: true,
    secondaryNavOpen: false,
  },
  searchBox: {
    open: false
  }
};

export default layout1Settings;
