import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export class TaskManagerList extends Component {
  state = {
    taskList: [
      {
        title: "Update User profile page",
        link: "/",
      },
      {
        title: "Not Update User profile page",
        link: "/",
      },
    ],
  };

  handlePageClick = (data) => {
    let currentPage = data.disabled;
    this.setState({ currentPage });
  };

  render() {
    let { taskList } = this.state;

    return (
      <div id="task-manager-list" className="h-100 card">
        {/* <!-- content area --> */}
        <div className="card-body">
          {/* <!-- task manager table --> */}
          <div className="simple-card-title">
            <h3 className="card-title task-title">Le tue attività</h3>
          </div>

          <div>
            <div className="search ul-task-manager__search-inline align-items-center">
              <div className="col-lg-6 col-md-12">
                <nav className="navbar">
                  <form className="d-flex">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label me-2"
                    >
                      Filtra:
                    </label>
                    <input
                      className="form-control me-sm-2"
                      id="filterInput"
                      type="search"
                      placeholder="digita il testo da filtrare.."
                      aria-label="Search"
                    />
                  </form>
                </nav>
              </div>
              <div className="col-4 d-none d-lg-inline">
                <label>
                  <span>Mostra:</span>
                  <select>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
            </div>

            <div className="table-responsive">
              <table
                id="names"
                className="table table-bordered custom-sm-width"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Attività</th>
                    <th scope="col">Priorità</th>
                    <th scope="col">Scadenza</th>
                    <th scope="col">
                      <span className="checkmarks">
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                      </span>
                    </th>
                  </tr>
                </thead>
                <thead className="bg-light">
                  <tr>
                    <th colSpan="7">Last Week</th>
                  </tr>
                </thead>
                <tbody id="names">
                  {taskList.map((task, ind) => (
                    <tr key={ind} id="names">
                      <th scope="row" className="head-width">
                        #{ind + 1}
                      </th>
                      <td className="collection-item">
                        <div className="font-weight-bold">
                          <Link to={task.link}>{task.title}</Link>
                        </div>
                        <div className="text-muted">
                          Lorem ipsum dolor sit amit...
                        </div>
                      </td>
                      <td className="custom-align">
                        <div className="btn-group ">
                          <Dropdown>
                            <Dropdown.Toggle
                              as="div"
                              className="toggle-hidden"
                            >
                              <button
                                className="btn btn-danger text-white custom-btn  btn-sm dropdown-toggle"
                                type="button"
                              >
                                Bloccante
                              </button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <span className="ul-task-manager__dot bg-primary me-2"></span>
                                Bloccante
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <span className="ul-task-manager__dot bg-danger me-2"></span>
                                Alta
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <span className="ul-task-manager__dot bg-warning me-2"></span>
                                media
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <span className="ul-task-manager__dot bg-success me-2 "></span>
                                Bassa
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>

                      <td className="custom-align">
                        <div className="d-inline-flex align-items-center calendar align-middle">
                          <i className="i-Calendar-4"></i>
                          <span className="">12 January 2015</span>
                        </div>
                      </td>

                      <td className="custom-align" valign="middle">
                        <Dropdown>
                          <Dropdown.Toggle
                            as="div"
                            className="toggle-hidden cursor-pointer"
                          >
                            <i
                              data-toggle="dropdown"
                              className="i-Align-Right custom-font-down-arrow"
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>Check In</Dropdown.Item>
                            <Dropdown.Item>
                              Attach Screenshot 2
                            </Dropdown.Item>
                            <Dropdown.Item>Reassign</Dropdown.Item>
                            <Dropdown.Item>Edit Task</Dropdown.Item>
                            <Dropdown.Item>Remove</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}

                  <tr className="bg-light">
                    <th colSpan="12">10 Days Ago</th>
                  </tr>

                  {/* <!-- table row 3 --> */}
                  <tr>
                    <th scope="row">#3</th>
                    <td className="collection-item">
                      <div className="font-weight-bold">
                        <Link to="/">Update User profile page</Link>
                      </div>
                      <div className="text-muted">
                        Lorem ipsum dolor sit amit...
                      </div>
                    </td>
                    <td className="custom-align">
                      <Dropdown>
                        <Dropdown.Toggle as="div" className="toggle-hidden">
                          <button
                            className="btn btn-warning custom-btn  btn-sm dropdown-toggle"
                            type="button"
                          >
                            Alta
                          </button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <span className="ul-task-manager__dot bg-primary me-2"></span>
                            Bloccante
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <span className="ul-task-manager__dot bg-danger me-2"></span>
                            Alta
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <span className="ul-task-manager__dot bg-warning me-2"></span>
                            media
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <span className="ul-task-manager__dot bg-success me-2 "></span>
                            Bassa
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>

                    <td className="custom-align">
                      <div className="d-inline-flex align-items-center calendar">
                        <i className="i-Calendar-4"></i>
                        <span>31/12/2021</span>
                      </div>
                    </td>
                    <td className="custom-align">
                      <Dropdown>
                        <Dropdown.Toggle
                          as="div"
                          className="toggle-hidden cursor-pointer"
                        >
                          <i
                            data-toggle="dropdown"
                            className="i-Align-Right custom-font-down-arrow"
                          ></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>Check In</Dropdown.Item>
                          <Dropdown.Item>Attach Screenshot 2</Dropdown.Item>
                          <Dropdown.Item>Reassign</Dropdown.Item>
                          <Dropdown.Item>Edit Task</Dropdown.Item>
                          <Dropdown.Item>Remove</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                  {/* <!-- end of table row 3 --> */}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer text-muted">
            <div className="d-flex flex-wrap justify-content-between align-items-center py-1">
              <span> pagina 1 di 2 of 9 attività</span>
              
            </div>
          </div>
          {/* <!-- end of task manager table --> */}
        </div>
        {/* <!-- end of content area --> */}
      </div>
    );
  }
}

export default TaskManagerList;
