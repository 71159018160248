import _get from "lodash/get";
import _set from "lodash/set";
import _isEqual from "lodash/isEqual";
import { DataContext, gql, useDataRequest } from "@ai4/data-request";
import { useCallback, useContext, useMemo, useRef } from "react";
import {
  getRoot,
  useFormBuilderSchemaLoader,
  useSelectItemsPopulate,
  useUpdateSchemaNode,
} from "src/@bootstrap/components/Crud/data";
// import { GET_COMUNI } from "./gql/anagrafica";

interface Args {
  module: string;
  entity: string;
  graphql: {
    list: any;
    details: any;
  };
  lists?: string[];
  lists_servizi?: string[];
}

const PROVINCE = gql`
  query getProvince {
    elenchiQuery {
      elenchiGeneraliQuery {
        nazioni {
          list {
            uniqueId
            codiceISO3166alpha3
            descrizione
          }
        }
        provincie {
          list {
            uniqueId
            siglaProvincia
            descrizione
          }
        }
      }
    }
  }
`;

interface ChangeComuniByProvinciaArgs {
  ns?: string;
  values: any;
  setValues: any;
  getSchemaNode: any;
  setSchemaNode: any;
}

// export const useChangeComuniByProvincia = () => {
//     const { domain, getClient } = useContext(DataContext);
// 	const { useQuery } = useDataRequest();
//     const province = useQuery(PROVINCE);
//     const client = getClient ? getClient(domain) : undefined;
//     const execComuni = useCallback(({ variables }: any) => {
//         if (client) {
//             return client.query({
//                 query: GET_COMUNI,
//                 variables
//             });
//         }
//         return Promise.resolve();
//     }, []);

//     const prevValues = useRef<Record<string, any>>();
//     const onChangeProvincia = useCallback((args: ChangeComuniByProvinciaArgs) => {
//         const { ns, values, setValues, getSchemaNode, setSchemaNode } = args;
//         const nazione = _get(province.data, "elenchiQuery.elenchiGeneraliQuery.nazioni.list", []).find(p => p.uniqueId === _get(values, `${ns ? `${ns}.` : ''}nazioneUniqueId`));
//         const provincia = _get(province.data, "elenchiQuery.elenchiGeneraliQuery.provincie.list", []).find(p => p.uniqueId === _get(values, `${ns ? `${ns}.` : ''}provinciaUniqueId`));
//         const names = {
//             comune: `${ns ? `${ns}.` : ''}comuneUniqueId`,
//             provincia: `${ns ? `${ns}.` : ''}provinciaUniqueId`,
//             nazione: `${ns ? `${ns}.` : ''}nazioneUniqueId`,
//             localita: `${ns ? `${ns}.` : ''}localita`,
//             cap: `${ns ? `${ns}.` : ''}cap`,
//             estero: `${ns ? `${ns}.` : ''}comuneEstero`,
//         };
//         const valuesA = Object.keys(names).reduce((acc, k) => ({ ...acc, [k]: _get(values, names[k]) }), {});
//         const valuesB = Object.keys(names).reduce((acc, k) => ({ ...acc, [k]: _get(prevValues.current, names[k]) }), {});
//         if (_isEqual(valuesA, valuesB)) {
//             return;
//         }
//         const isEstero = nazione && nazione.codiceISO3166alpha3 !== 'ITA';
//         const estero = getSchemaNode(names.estero);
//         if (estero) {
//             setSchemaNode(names.estero, {
//                 ...estero,
//                 props: { ...(estero.props || {}), disabled: !isEstero }
//             });
//         }
//         ['provincia', 'comune', 'localita', 'cap'].forEach((k) => {
//             const node = getSchemaNode(names[k]);
//             if (node) {
//                 setSchemaNode(names[k], {
//                     ...node,
//                     props: { ...(node.props || {}), disabled: isEstero }
//                 });
//             }
//         });
//         const node = getSchemaNode(names.comune);
//         if (node) {
//             if (provincia && !isEstero) {
//                 execComuni({ variables: { provincia: provincia.siglaProvincia }}).then(res => {
//                     const items = _get(res.data, 'elenchiQuery.elenchiGeneraliQuery.comuni.list', [])
//                     setSchemaNode(names.comune, {
//                         ...node,
//                         props: {
//                             ...(node.props || {}),
//                             items: items.map(_assoc),
//                             disabled: false,
//                         },
//                     });
//                 })
//             } else {
//                 setSchemaNode(names.comune, {
//                     ...node,
//                     props: { ...(node.props || {}), disabled: true }
//                 });
//             }
//         }
//         prevValues.current = { ...values };
//     }, [province]);

//     return {
//         onChangeProvincia
//     }
// }

export const _assoc = (cat: any) => ({
  text: cat.descrizione,
  value: cat.uniqueId,
});

export const getEditFormViewer = (props: any) => {
  const {
    record,
    onAddExtraData,
    handleClose,
    onSelectFiles,
    onDownloadFile,
    onRemoveFile,

    formSchema,
    formOptions,
    onSetHelpers,
    onInit,
    onChange,
    onDirty,
    onValidate,
    onSubmit,
    onCancel,
    onSuccess,
    onError,
    submitResponse,
    ...rest
  } = props;

  return {
    ...rest,
    schema: formSchema,
    initialValues: record || null,
    options: formOptions,
    onCancelWhenModified: () => {
      return `Continuando perderai tutte le tue modifiche. Vuoi continuare?`;
    },
    onInit: (form: any) => {
      if (onSetHelpers) onSetHelpers(form);
      if (onInit) onInit(form);
    },
    onChange: (form: any) => {
      if (onChange)
        onChange({
          ...form,
          setExtraData: onAddExtraData,
        });
      if (onDirty) onDirty(form.dirty);
    },
    onValidate: (args: any) => {
      const { values, extraData } = args;
      if (onValidate) {
        return onValidate({
          values: {
            ...values,
            ...extraData,
          },
        });
      }
      return null;
    },
    onSubmit: (args: any) => {
      const { values, form, extraData } = args;
      const newArgs = { ...args, values: { ...values, ...extraData } };
      onSubmit(newArgs)
        .then((res) => {
          const values = res.data;
          // form.resetForm({ values });
          if (onSuccess) onSuccess(values);
        })
        .catch((err) => {
          if (onError) onError(err);
        })
        .finally(() => {
          form.setSubmitting(false);
        });
    },
  };
};
