import { useCallback, useEffect, useState } from "react";
import { useDataRequest } from "@ai4/data-request";
import ProjectsChart from "./components/ProjectsChart";
import UltimiResi from "./components/UltimiResi";
import PrestitiInScadenza from "./components/PrestitiInScadenza";
import UltimeOpereInserite from "./components/UltimeOpereInserite";

function DashboardSupervisor() {
  const [data, setData] = useState({});
  const { useRestRequest } = useDataRequest();
  const [req] = useRestRequest({
    path: "rest/{ver}/dashboard/summary",
    method: "GET",
    jwt: true,
  });

  useEffect(() => {
    load();
  }, []);

  const load = useCallback(async () => {
    const res = await req({
      queryString: {
        enableUltimiResi: true,
        enablePrestitiInScadenza: true,
        enableUltimiUtentiRegistrati: true,
        enableUltimeOpereInserite: true,
      },
    });
    setData(res);
  }, []);

  const state = {
    cardList1: [
      {
        icon: "i-Address-Book",
        title: data.numeroUtenti,
        subtitle: "Utenti",
      },
      {
        icon: "i-Folders",
        title: data.numeroOpere,
        subtitle: "Opere",
      },
      {
        icon: "i-Over-Time-2",
        title: data.numeroPrenotazioni,
        subtitle: "Prenotazioni",
      },
      {
        icon: "i-Calendar-4",
        title: data.numeroPrestiti,
        subtitle: "Prestiti",
      },
    ],
  };

  let { cardList1 = [] } = state;

  return (
    <div>
      {/* <Breadcrumb
        routeSegments={[
          { name: "Dashboard", path: "dashboard/users/DashboardUsers" },
          { name: "Amministratore" },
        ]}
      ></Breadcrumb> */}

      <div className="row">
        {cardList1.map((card, index) => (
          <div key={index} className="col-lg-3 col-md-6 col-sm-6">
            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
              <div className="card-body text-center">
                <i className={card.icon}></i>
                <div
                  className="content"
                  style={{ maxWidth: "none", display: "flow-root" }}
                >
                  <p className="text-muted mt-2 mb-0 text-capitalize">
                    {card.subtitle}
                  </p>
                  <p className="lead text-primary text-24 mb-2 text-capitalize">
                    {card.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6" style={{ paddingBottom: "15px" }}>
          <UltimiResi data={data.ultimiResi}></UltimiResi>
        </div>
        <div className="col-lg-6 col-md-6" style={{ paddingBottom: "15px" }}>
          <PrestitiInScadenza
            data={data.prestitiInScadenza}
          ></PrestitiInScadenza>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6" style={{ paddingBottom: "15px" }}>
          <ProjectsChart
            data={data.ultimiUtentiRegistrati}
            height={400}
          ></ProjectsChart>
        </div>
        <div className="col-lg-6 col-md-6" style={{ paddingBottom: "15px" }}>
          <UltimeOpereInserite
            data={data.ultimeOpereInserite}
          ></UltimeOpereInserite>
        </div>
      </div>
    </div>
  );
}

export default DashboardSupervisor;
