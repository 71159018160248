import { Formatters } from "@ai4/records-management";
import React, { Component } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import TableWidget from "./TableWidget";
import EmptyBoardWidget from "./EmptyBoardWidget";

interface Props {
  data?: {
    codice: string;
    codiceFiscale: string;
    data: number;
    struttura: string;
    uniqueId: string;
  }[];
}

export function UltimiResi(props: Props) {
  const { data = [] } = props;
  const state = {
    dataTablePage: 0,
    dataTablePerPage: 5,
  };

  let { dataTablePage, dataTablePerPage } = state;

  let dotStyle = { marginLeft: "4px", backgroundColor: "black !important" };

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="ul-widget__head border-0 mb-2">
          <div className="ul-widget__head-label">
            <h3 className="ul-widget__head-title">Ultimi Resi</h3>
          </div>
        </div>
        {data.length > 0 ? (
          <TableWidget
            data={data}
            ipp={dataTablePerPage}
            head={() => (
              // Opera	Data	azione
              <thead>
                <tr className="ul-widget6__tr--sticky-th">
                  <th scope="col">Opera</th>
                  <th scope="col">Data</th>
                  {/* {!isMobile && (
                  <>
                    <th scope="col">Cod. Fiscale</th>
                    <th scope="col">Struttura</th>
                  </>
                )} */}
                  <th scope="col">Azione</th>
                </tr>
              </thead>
            )}
            row={(item, i) => (
              <tr key={i}>
                <td>
                  <span>{item.opera}</span>
                </td>
                <td>{Formatters.dateFormatter(item.resoIl, item, i, null)}</td>
                {/* {!isMobile && (
                <>
                  <td>{item.codiceFiscale}</td>
                  <td>{item.struttura}</td>
                </>
              )} */}
                <td>
                  <a href={`/resi/dettaglio/${item.uniqueId}`} target="_blank">
                    <i className="bi bi-arrow-right-circle" />
                  </a>
                </td>
              </tr>
            )}
          />
        ) : (
          <EmptyBoardWidget text="Nessun reso recente"></EmptyBoardWidget>
        )}
      </div>
    </div>
  );
}

export default UltimiResi;
