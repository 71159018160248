import { lazy } from "react";
import Crud from "../../../@bootstrap/components/Crud/Crud";
import ResiDettaglio from "./ResiDettaglio";

const resiRoutes = [
  {
    path: "resi/List",
    element: <Resi />,
    // auth: authRoles.admin
  },
  {
    path: "resi/dettaglio/:id",
    element: <ResiDettaglio />,
    // auth: authRoles.admin
  },
];

function Resi() {
  return <Crud module="resi" entity="resi" />;
}

export default resiRoutes;
