import _get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack } from 'react-bootstrap';
import { useDataRequest } from "@ai4/data-request";
import { SchemaTypes, traverseSchema } from "@ai4/form-viewer";
import Schema from "@ai4/form-viewer/dist/types/schema";

type ReturnedSchema = Schema | null | undefined;

export const useFormBuilderSchemaLoader = (mutation: string | string[], version?: number | Record<string, number>) => {
    const [schema, setSchema] = useState();
    const [schemas, setSchemas] = useState<Record<string, ReturnedSchema>>();
    const { useRestRequest } = useDataRequest();
	const [req, res] = useRestRequest({
		path: `api/{ver}/forms`,
		jwt: true,
	});
    
    const loadSchema = async (path: string, version?: number): Promise<Schema | null> => {
		if (!path) return null;
		const [group, ...rest] = path.split('.');
		const entity = rest.join('.');
		try {
			let queryString = {
				moduleNodeName: group,
				entityNodeName: entity,
			} as any;
			if (version) {
				queryString = { ...queryString, version };
			}
			return await req({
				method: 'GET',
				queryString,
			}) as Schema;
            
		} catch (e) {
			return null
		}
	};

    useEffect(() => {
		(async () => {
			if (!Array.isArray(mutation)) {
				const json = await loadSchema(mutation, version as number);
				setSchema(json as any);
			} else {
				const schemas = await mutation.reduce<Promise<Record<string, Schema>>>(async (acc, path) => {
					let q = await acc;
					const schema = await loadSchema(path, (version as Record<string, number> || {})[path]);
					if (schema) {
						q[path] = schema;
					}
					return q;
				}, Promise.resolve({}));
				setSchemas(schemas);
			}
		})();
    }, []);

	if (!Array.isArray(mutation)) {
    	return schema ? schema as SchemaTypes.Schema : null;
	} else {
		return schemas ? schemas : null;
	}
}

export const useFormBuilderSchemaMerger = (mutations: string[], version?: Record<string, number>): ReturnedSchema => {
	const schemasMap = useFormBuilderSchemaLoader(mutations, version) as Record<string, Schema>;
	const schemas = schemasMap ? Object.values(schemasMap) : null;
	const loading = !schemas || schemas.filter(schema => schema === undefined).length > 0;
	if (loading) return undefined;
	return {
		defaultActiveTabId: "Titolo",
		id: "root",
		type: "tabs",
		tabs: [{
			id: "Titolo",
			title: "Titolo",
			rows: schemas.reduce<any>((acc, schema) => [...acc, ..._get(schema, 'tabs[0].rows', [])], [])
		}]
	} as SchemaTypes.Schema;
}

export const useFormBuilderSchemaLastVer = (path: string) => {
	const [ver, setVer] = useState<number>();
	const [group, ...rest] = path.split('.');
	const entity = rest.join('.');
	const { useRestRequest } = useDataRequest();
	const [req] = useRestRequest({
		path: `api/{ver}/forms/versions`,
		jwt: true,
	});
	
	useEffect(() => {
		req({
			method: 'GET',
			queryString: {
				moduleNodeName: group,
				entityNodeName: entity,
			},
		}).then((res) => { 
			const max = Math.max.apply(Math, (res as { version: number }[]).map(r => r.version));
			setVer(max);
		});
	}, []);

	return ver;
}

export function useUpdateSchemaNode(schema: ReturnedSchema, nodeName: string, updater: (node: any) => any) {
	if (!schema) return null;

	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && name === nodeName) {
			return updater(n);
		}
		return n;
	});
}

type SelectItemsMap = Record<string, { text?: string, value?: string, creationURL?: string, listManagement?: { name: string; query_name: string; query_path: string; }, button?: () => JSX.Element }[]>;
interface SelectItemsOptions {
	noRefresh?: boolean;
}
export function useSelectItemsPopulate(schema: ReturnedSchema, query: any, map: SelectItemsMap, options?: SelectItemsOptions) {
	const { data, refetch } = query;
	const { noRefresh } = options || {};
	if (!schema) return schema;
    if (!data) return null;

	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && map[name]) {
			const { creationURL } = map[n.name].find(i => i.creationURL) || {};
			const { button } = map[n.name].find(i => i.button) || {};
			var action;
			if (creationURL) action = `${creationURL}#create`;
			if (button) action = button;
			return {
				...n,
				props: {
					...(n.props || {}),
					items: map[name].filter(i => !!i.value),
					creationURL: action,
					onRefresh: noRefresh ? undefined : () => {
						refetch();
					}
				}
			};
		}
		return n;
	});
}

export function useHideNodesSchema(schema: Schema | null | undefined, ids: string[]) {
	if (!schema) return null;
	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && ids.includes(name)) {
			return undefined;
		}
		return n;
	});
}

export function useKeepRequiredNodesSchema(schema: Schema | null, ids: string[]) {
	if (!schema) return null;
	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name && ids.includes(name)) {
			return {
				...n,
				validation: [{
					rule: 'required',
				}]
			};
		}
		return n;
	});
}

export function useDataDecorator(path: string, data: any) {
	const fix = useCallback((row) => {
		return Object.keys(row).reduce((acc, k: string) => {
			const sub = row[k];
			if (Array.isArray(sub)) {
				acc[k] = sub.map(fix);
			} else if (sub && typeof sub === 'object') {
				const subId = _get(sub, 'uniqueId');
				if (subId) {
					acc[`${k}UniqueId`] = subId;
				}
				acc[k] = fix(sub);
			}
			return acc;
		}, {...row});
	}, []);

	const rows = useMemo(() => {
		return _get(data, path, []).map((row: any) => {
			return fix(row);
		});
	}, [data]);

	return data ? rows : undefined;
}

interface UseTreeDecoratorArgs {
	labelField?: string;
}

export function useTreeDecorator(rows: any[], args?: UseTreeDecoratorArgs) {
	const { labelField = 'descrizione' } = args || {};
	const parents = rows.filter(row => !row.padre);
	const tree = parents.reduce((acc, parent) => {
		acc.push({
		  ...parent
		});
		return [
		  ...acc,
		  ...rows.filter(row => _get(row, 'padre.uniqueId') === parent.uniqueId).map(row => {
			return {
			  ...row,
			  [labelField]: `└── ${row[labelField]}`,
			}
		  })
		]
	}, []);
	return tree;
}

/***
 *	get versioned base url
 */
export function base(type: 'rest' | 'api' = 'rest') {
	return `${type}/v1`;
}

/***
 *	get content
 */
export function getRoot(schema) {
	return _get(schema, 'tabs[0].rows')
}

/***
 * usefull to manually put a form-viewer into a CRUD custom edit
 */
export const getEditFormViewer = (props: any) => {
    const {
        record,
        onAddExtraData,
        handleClose,
        onSelectFiles,
        onDownloadFile,
        onRemoveFile,

        formSchema,
        formOptions,
        onSetHelpers,
        onInit,
        onChange,
        onDirty,
        onValidate,
        onSubmit,
        onCancel,
        onSuccess,
        onError,
        submitResponse,
        ...rest
    } = props;

    return {
        ...rest,
        schema: formSchema,
        initialValues: record || null,
        options: formOptions,
        onCancelWhenModified: () => {
            return `Continuando perderai tutte le tue modifiche. Vuoi continuare?`;
        },
        onInit: (form: any) => {
            if (onSetHelpers) onSetHelpers(form);
            if (onInit) onInit(form);
        },
        onChange: (form: any) => {
            if (onChange)
                onChange({
                    ...form,
                    setExtraData: onAddExtraData,
                });
            if (onDirty) onDirty(form.dirty);
        },
        onValidate: (args: any) => {
            const { values, extraData } = args;
            if (onValidate) {
                return onValidate({
                    values: {
                        ...values,
                        ...extraData,
                    },
                });
            }
            return null;
        },
        onSubmit: (args: any) => {
            const { values, form, extraData } = args;
            const newArgs = { ...args, values: { ...values, ...extraData } };
            onSubmit(newArgs)
                .then((res) => {
                    const values = res.data;
                    // form.resetForm({ values });
                    if (onSuccess) onSuccess(values);
                })
                .catch((err) => {
                    if (onError) onError(err);
                })
                .finally(() => {
                    form.setSubmitting(false);
                });
        },
		slots: {
			ButtonBar: (props: any) => {
				const { form } = props;
				return (
					<Stack direction='horizontal' gap={3}>
						<Button variant='secondary' type='button' onClick={() => onCancel()}>
							Annulla
						</Button>
						<Button
							variant='primary'
							type='submit'
							disabled={!form.isValid || form.isSubmitting}
							className='ms-auto'
						>
							Salva
						</Button>
					</Stack>
				);
			},
		},
    }
}