interface Props {
  text: string;
}

function EmptyBoardWidget(props: Props) {
  const { text } = props;
  return (
    <div className="text-center">
      <div
        className="content"
        style={{ maxWidth: "none", display: "flow-root" }}
      >
        <p className="text-muted mt-2 mb-0">{text}</p>
      </div>
    </div>
  );
}

export default EmptyBoardWidget;
