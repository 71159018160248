import _get from "lodash/get";
import { SchemaTypes } from "@ai4/form-viewer";
import $schema from "./piani.schema.json";
import {
  filterDefault,
  pathToSlug,
} from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import { isMobile } from "react-device-detect";
import { textFilter } from "@ai4/react-bootstrap-table2-filter";

let schema = $schema as SchemaTypes.Schema;

// export const QUERY = gql`
//   query getPiani {
//     bibliotecaQuery {
//       piani {
//         list {
//           uniqueId
//           nome
//         }
//       }
//     }
//   }
// `;

export const SAVE = gql`
  mutation addPiano($data: PianoInput) {
    bibliotecaMutation {
      piano {
        salva(piano: $data) {
          uniqueId
          nome
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation deletePiano($uniqueId: String) {
    bibliotecaMutation {
      piano {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = () => {
  const list = "bibliotecaQuery.piani.list";
  const mutation = "bibliotecaMutation.piano";
  const { useMutation } = useDataRequest();
  // const query = useLazyQuery(QUERY);
  // const [execDetails, { data: dataDetails, loading }] = query;
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });

  return {
    graphql: {
      list,
      mutation,
      // loading,
      save: execSave,
      remove: execDelete,
      // details: execDetails,
    },
    schema,
    results: {
      save: resSave,
      remove: resDelete,
    },
    // dataDetails,
    extra: {
      options: {
        closeWhenSaved: true,
      },
    },
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "nome",
          text: "Nome",
          filter: textFilter(filterDefault()),
        },
      ],
      dateNascoste: isMobile,
    },
  };
};
