import _get from "lodash/get";
import { useMemo, useState, useEffect } from "react";
import { getEditFormViewer } from "../common";
import Edit from "src/app/components/Edit";
import { FormViewer } from "@ai4/form-viewer";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import TitleBar from "src/app/components/TitleBar";

export function EditPrenotazioni(props: any) {
  const { record, onAddExtraData, handleClose } = props;

  const formArgs = useMemo(() => {
    const { initialValues, ...args } = getEditFormViewer(props);
    return {
      ...args,
      initialValues: {
        ...initialValues,
      },
    };
  }, [props]);

  const [extraData, setExtraData] = useState({
    resoIl: _get(record, "resoIl", undefined) || undefined,
  });

  useEffect(() => {
    onAddExtraData({
      ...extraData,
    });
  }, [extraData]);

  const [showDetailRow, setShowDetailRow] = useState(false);
  const handleCloseDetailRow = () => setShowDetailRow(false);
  const handleShowDetailRow = () => setShowDetailRow(true);

  const [prenotatoAl, setPrenotatoAlDate] = useState(false);

  const handleSaveExtraData = () => {
    console.log("handleSaveExtraData");
    const resoIl = new Date();
    setExtraData({ ...extraData, resoIl });

    // handleCloseModal();
  };

  const operaState = (prenotazioni, selectedOpera) => {
    //se non c'è un uniqueid e se c'è selezionata un'opera
    console.log("prenotazioni", prenotazioni);
    console.log("selectedOpera", selectedOpera);

    const prenotazioniWithSelectedOpera = prenotazioni.filter(
      (element) => element.operaUniqueId === selectedOpera
    );
    console.log("prenotazioneFound", prenotazioniWithSelectedOpera);
    let operaDisponibile = true;
    if (prenotazioniWithSelectedOpera) {
      operaDisponibile =
        prenotazioniWithSelectedOpera[0].opera.stato === "DISPONIBILE";
    }

    console.log("opera disponibile? ", operaDisponibile);
    if (!operaDisponibile) {
      let higherDatePrenotazione;
      if (prenotazioniWithSelectedOpera.length > 1) {
        const prestatoAlArray = prenotazioniWithSelectedOpera.map(
          (v) => new Date(v.prestatoAl)
        );
        higherDatePrenotazione = new Date(
          Math.max.apply(null, prestatoAlArray)
        );
        console.log("prestatoAlArray", higherDatePrenotazione);
      } else {
        //TODO: da testare
        higherDatePrenotazione = new Date(
          prenotazioniWithSelectedOpera[0].prestatoAl
        );
      }

      setPrenotatoAlDate(higherDatePrenotazione);
    }

    !operaDisponibile ? handleShowDetailRow() : handleCloseDetailRow();
  };

  return (
    <Edit>
      <TitleBar
        path={[
          `${record.uniqueId ? record.opera.titolo : "Nuova prenotazione"}`,
        ]}
        onBack={handleClose}
      />
      <FormViewer
        {...formArgs}
        onChange={(form) => {
          //controllare anche che nel caso si resetti il campo
          if (
            !record.uniqueId &&
            form.changedValues.hasOwnProperty("operaUniqueId")
          ) {
            operaState(formArgs.rows, form.changedValues.operaUniqueId);
          }
        }}
      />
      {showDetailRow ? (
        <>
          <Container>
            <Row>
              <Col>{"prenotato fino al: " + prenotatoAl}</Col>
            </Row>
          </Container>
        </>
      ) : (
        ""
      )}
      {/* POPUP */}
      {/* <Modal show={showodal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title> }
        </Modal.Header>
        <Modal.Body>Confermi il reso?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annulla
          </Button>
          <Button variant="primary" onClick={handleSaveExtraData}>
            Conferma
          </Button>
        </Modal.Footer>
        <Modal.Footer></Modal.Footer>
      </Modal> */}

      {/* BOTTONE CHE FA COMPARIRE IL POPUP */}
      {/* <Button style={{}} onClick={handleShowodal}>
        Esegui Reso
      </Button> */}
    </Edit>
  );
}

export default EditPrenotazioni;
