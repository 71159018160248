import React, { useState, useCallback, useEffect, useContext, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, Toast, ToastContainer } from "react-bootstrap";
import { useAuthRequest } from "@ai4/data-request";
import { loggedIn, selectAuthenticated } from "src/@bootstrap/features/auth";
import { ConfigContext } from "src/@bootstrap/Config";

const SigninSchema = yup.object().shape({
    email: yup.string().email().required("email is required"),
    password: yup
        .string()
        .min(4, "Password must be 8 character long")
        .required("password is required"),
});

interface Props {
    inModal?: boolean;
}

function Signin(props: Props) {
    const { inModal } = props;
    const { getEnv } = useContext(ConfigContext);
    const [data, setData] = useState<Record<string, string>>({
        email: getEnv("REACT_APP_PRESET_USERNAME") || "",
        password: getEnv("REACT_APP_PRESET_PASSWORD") || "",
    });
    const [error, setError] = useState();
    const [loginExec] = useAuthRequest({ action: "login" });
    const navigate = useNavigate();
    const auth = useSelector(selectAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth) {
            navigate("/");
        }
    }, [auth]);

    const handleSubmit = useCallback(
        (value, { isSubmitting }) => {
            loginExec({
                data: value,
                headers: {
                    preventPropagateErrors: true,
                },
                onSuccess: (res) => {
                    dispatch(
                        loggedIn({
                            role: "SA",
                        })
                    );
                },
                onError: (err) => {
                    const { resultType } = err;
                    if (resultType === "SET_FIRST_PASSWORD") {
                        navigate("/session/change-password");
                    }
                    setError(err.messages.join('\n'));
                },
            });
        },
        [loginExec]
    );

    const LoginForm = useCallback(() => {
        return <Formik
            initialValues={data}
            validationSchema={SigninSchema}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">E-mail</label>
                        <input
                            className="form-control form-control-rounded position-relative"
                            type="text"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        {errors.email && (
                            <div className="text-danger mt-1 ml-2">
                                {errors.email}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            className="form-control form-control-rounded"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                        />
                        {errors.password && (
                            <div className="text-danger mt-1 ml-2">
                                {errors.password}
                            </div>
                        )}
                    </div>
                    <button
                        className="btn btn-rounded btn-primary w-100 my-1 mt-2"
                        type="submit"
                    >
                        Conferma
                    </button>
                </form>
            )}
        </Formik>
    }, []);

    const Errors = useCallback(() => {
        return <Fragment>
            {error && (
                <>
                    <ToastContainer position={"middle-center"}>
                        <Toast onClose={() => setError(undefined)}>
                            <Toast.Header>
                                <img
                                    src="holder.js/20x20?text=%20"
                                    className="rounded me-2"
                                    alt=""
                                />
                                <strong className="me-auto">Error</strong>
                            </Toast.Header>
                            <Toast.Body>{error}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </>
            )}
        </Fragment>
    }, [error]);

    if (inModal) {
        return <Fragment>
            <Modal show centered>
                <Modal.Header>
                    <Modal.Title>
                        Accedi
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {LoginForm()}
                </Modal.Body>
            </Modal>
            {Errors()}
        </Fragment>
    }

    return (
        <>
            <div
                className="auth-layout-wrap"
                style={{
                    backgroundImage: "url(/assets/images/photo-wide-5.jpg)",
                }}
            >
                <div className="auth-content">
                    <div className="card o-hidden">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="p-4">
                                    <div className="auth-logo text-center mb-4">
                                        <img src="/assets/images/logo.png" alt="" />
                                    </div>
                                    <h1 className="mb-3 text-18">Accedi</h1>
                                    {LoginForm()}

                                    <div className="mt-3 text-center">
                                        <Link to="/session/change-password" className="text-muted">
                                            <u>Devi cambiare la password?</u>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Errors()}
        </>
    );
}

export default Signin;