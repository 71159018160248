import React from "react";
import ReactEcharts from "echarts-for-react";

const BudgetChart = ({ height, color = [] }) => {
  const option = {
    legend: {
      borderRadius: 0,
      orient: "horizontal",
      x: "right",
      data: ["Preventivo", "Consuntivo"]
    },
    grid: {
      left: "8px",
      right: "8px",
      bottom: "0",
      containLabel: true
    },
    tooltip: {
      show: true,
      backgroundColor: "rgba(0, 0, 0, .8)"
    },
    xAxis: [
      {
        type: "category",
        data: [
            "Set 21",
            "Ott 21",
            "Nov 21",
            "Dic 21",
            "Gen 22",
            "Feb 22",
        ],
        axisTick: {
          alignWithLabel: true
        },
        splitLine: {
          show: false
        },
        axisLine: {
          show: true
        }
      }
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: "€ {value}"
        },
        min: 0,
        max: 80000,
        interval: 20000,
        axisLine: {
          show: false
        },
        splitLine: {
          show: true,
          interval: "auto"
        }
      }
    ],

    series: [
      {
        name: "Preventivo",
        data: [
          35000,
          69000,
          22500,
          60000,
          50000,
          51000,
        ],
        label: { show: false, color: "#0168c1" },
        type: "bar",
        barGap: 0,
        color: "#bcbbdd",
        smooth: true,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: -2,
            shadowColor: "rgba(0, 0, 0, 0.3)"
          }
        }
      },
      {
        name: "Consuntivo",
        data: [
          45000,
          72000,
          35000,
          58000,
          45000,
          54000,
        ],
        label: { show: false, color: "#639" },
        type: "bar",
        color: "#7569b3",
        smooth: true,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: -2,
            shadowColor: "rgba(0, 0, 0, 0.3)"
          }
        }
      }
    ]
  };

  return <ReactEcharts style={{ height: height }} option={option} />;
};

export default BudgetChart;
