import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Formatters } from "@ai4/records-management";
// , Helpers
import TableWidget from "./TableWidget";
import EmptyBoardWidget from "./EmptyBoardWidget";

interface Props {
  data?: {
    uniqueId: string;
    cartellaUniqueId: string;
    codice: string;
    assistito: string;
    dataInizio: string;
    dataChiusura: string;
    stato: number;
  }[];
}

export function PrestitiInScadenza(props: Props) {
  const { data = [] } = props;
  const state = {
    dataTablePage: 0,
    dataTablePerPage: 5,
  };

  let { dataTablePage, dataTablePerPage } = state;

  let dotStyle = { marginLeft: "4px", backgroundColor: "black !important" };

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="ul-widget__head border-0 mb-2">
          <div className="ul-widget__head-label">
            <h3 className="ul-widget__head-title">Prestiti in scadenza</h3>
          </div>
        </div>
        {data.length > 0 ? (
          <TableWidget
            data={data}
            ipp={dataTablePerPage}
            head={() => (
              <thead>
                <tr className="ul-widget6__tr--sticky-th">
                  <th scope="col">Opera</th>
                  <th scope="col">Utente</th>
                  {!isMobile && (
                    <>
                      <th scope="col">Inizio</th>
                    </>
                  )}
                  <th scope="col">Conclusione</th>
                  <th scope="col">Azione</th>
                </tr>
              </thead>
            )}
            row={(item, i) => (
              <tr
                key={
                  i
                } /*className={ pratica.fine === "10/03/2022" ? "list-group-item-danger" : "" }*/
              >
                <td>
                  <span>{item.opera}</span>
                </td>
                <td>{item.utente}</td>
                {!isMobile && (
                  <>
                    <td>
                      {Formatters.dateFormatter(
                        item.prestatoDal,
                        item,
                        i,
                        null
                      )}
                    </td>
                  </>
                )}
                <td>
                  {Formatters.dateFormatter(item.prestatoAl, item, i, null)}
                </td>
                <td>
                  <a
                    href={`/prestiti/dettaglio/${item.uniqueId}`}
                    target="_blank"
                  >
                    <i className="bi bi-arrow-right-circle" />
                  </a>
                </td>
              </tr>
            )}
          />
        ) : (
          <EmptyBoardWidget text="Nessun prestito in scadenza"></EmptyBoardWidget>
        )}
      </div>
    </div>
  );
}

export default PrestitiInScadenza;
