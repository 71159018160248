import { Action, configureStore, ConfigureStoreOptions, EnhancedStore, ThunkAction } from "@reduxjs/toolkit";

var store: EnhancedStore;
export function buildStore(options: ConfigureStoreOptions) {
    store = configureStore(options);
}

export function getStore() {
  return store;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;