import _get from "lodash/get";
import { SchemaTypes } from "@ai4/form-viewer";
import $schema from "./autori.schema.json";
import {
  filterDefault,
  pathToSlug,
} from "src/@bootstrap/components/Crud/helpers";

import { gql, useDataRequest } from "@ai4/data-request";
import { isMobile } from "react-device-detect";
import { textFilter } from "@ai4/react-bootstrap-table2-filter";
import { ModuleListOptions } from "../generi/generi";

let schema = $schema as SchemaTypes.Schema;

// export const QUERY = gql`
//   query getAutori {
//     bibliotecaQuery {
//       autori {
//         list {
//           uniqueId
//           nome
//         }
//       }
//     }
//   }
// `;

export const SAVE = gql`
  mutation addAutore($data: AutoreInput) {
    bibliotecaMutation {
      autore {
        salva(autore: $data) {
          uniqueId
          nome
        }
      }
    }
  }
`;

export const DELETE = gql`
  mutation addAutore($uniqueId: String) {
    bibliotecaMutation {
      autore {
        elimina(uniqueId: $uniqueId)
      }
    }
  }
`;

export const useModule = (options: ModuleListOptions) => {
  const list = "bibliotecaQuery.autori.list";
  const mutation = "bibliotecaMutation.autore";
  const { useLazyQuery, useQuery, useMutation } = useDataRequest();
  // const query = useLazyQuery(QUERY);
  // const [execDetails, { data: dataDetails, loading }] = query;
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [pathToSlug(list)],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [pathToSlug(list)],
  });

  return {
    graphql: {
      list,
      mutation,
      // loading,
      save: execSave,
      remove: execDelete,
      // details: execDetails,
    },
    schema,
    results: {
      save: resSave,
      remove: resDelete,
    },
    // dataDetails,
    extra: {
      options: {
        closeWhenSaved: true,
      },
    },
    table: {
      columns: [
        {
          dataField: "uniqueId",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "nome",
          text: "Nome",
          filter: textFilter(filterDefault()),
        },
      ],
      dateNascoste: isMobile || options.isLista,
    },
  };
};
