import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormViewer, SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import $schema from './ruoli.schema.json';
import { useDataRequest } from '@ai4/data-request';

let schema = $schema as SchemaTypes.Schema;

interface PreProps {
    values?: any;
    onChange: (values: any) => void;
}

interface Props extends PreProps {
    roles: any[];
}

const PreRuoli = (props: PreProps) => {
    const { useRestRequest } = useDataRequest();
    const [reqRoles, resRoles] = useRestRequest({
        path: 'api/{ver}/roles',
        method: 'GET',
        jwt: true,
    });

    useEffect(() => {
        reqRoles();
    }, []);

    if (!resRoles.data) return <Spinner />
    return <Ruoli {...props} roles={resRoles.data} />
}

const Ruoli = (props: Props) => {
    const { roles, values, onChange } = props;
    const [current, setCurrent] = useState();

    const { useRestRequest } = useDataRequest();
    
    const [reqUserRoles, ] = useRestRequest({
        path: 'api/{ver}/users/{id}/roles',
        method: 'GET',
        jwt: true,
    });

    const loadUserRoles = useCallback(async (id) => {
        const resUserRoles = await reqUserRoles({
            path: `api/{ver}/users/${id}/roles`,
            method: 'GET',
            jwt: true,
        }) as any;
        const $ruoli = resUserRoles.map(r => r.roleId);
        console.log(roles);
        setCurrent($ruoli);
        onChange($ruoli.map(r => roles.find(r1 => r1.id === r)));
    }, []);
    
    useEffect(() => {
        if (values.id) loadUserRoles(values.id);
    }, [values]);

    schema = useMemo(() => {
        return traverseSchema(schema, (n: SchemaTypes.SchemaNode) => {
            if (n.id && n.id === '$ruoli') return {
                ...n,
                props: {
					multiple: true,
                    items: roles.map(role => ({ text: role.name, value: role.id }))
                }
            };
            return n;
        });
    }, [schema]);

    if (!current) return null;
    return <FormViewer
        schema={schema}
        initialValues={{ $ruoli: current }}
        onChange={(args) => {
            const { values, form } = args;
            const { $ruoli } = values;
            onChange($ruoli.map(r => roles.find(r1 => r1.id === r)));
        }}
        slots={{
            ButtonBar: () => <></>,
        }}
    />
}

export default PreRuoli;